import React from "react";
import { Button, Col, Modal, Row } from "antd";
import BannerImage from "../../../../images/Driver/GroupBanner.png";
type Props = {};

const Banner = (props: Props) => {
  return (
    <section className="sectionOne container mx-auto pt-4 pb-16 border-b border-[#C6C6C6]">
      <Row gutter={[40, 16]}>
        <Col lg={12} sm={24}>
          <img src={BannerImage} alt="" className="h-auto max-w-full" />
        </Col>
        <Col lg={12} sm={24}>
          <div className="flex flex-col items-baseline justify-center h-full">
            <div className="sub-text">
              <h1 className="font-Roboto font-black sectionOneText text-FA0001 mt-4 lg:mt-0">
                Rev Up Your Earnings
              </h1>
              <p className="font-Roboto font-black text-2xl m-auto  lg:m-0">
                Join Us Today and Drive Toward Success!
              </p>
              <Button className="bg-FA0001 ant-custom-btn border-FA0001 font-black text-white text-2xl px-5 rounded-xl h-14 mt-7">
                Get Started
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Banner;
