import React from "react";
import MainImage from "../../../images/publicWebsites/Landing/sectionTwo.png";
import { Button, Col, Row } from "antd";
type Props = {};

const Section2 = (props: Props) => {
  return (
    <section className="sectionTwo mt-20 container mx-auto">
      <div className="flex justify-between xl:items-end items-center lg:flex-nowrap flex-wrap">
        <div>
          <h1 className="font-Roboto font-extrabold text-4xl lg:text-6xl lg:leading-[5rem]">
            Delivering Smiles{" "}
            <p className="font-Roboto font-extrabold text-4xl lg:text-6xl lg:leading-[4rem]">
              With Last Mile{" "}
              <span className="text-FA0001 font-Roboto text-4xl font-extrabold lg:text-6xl">
                Solutions.
              </span>
            </p>
          </h1>
        </div>
        <Button className="bg-FA0001 ant-custom-btn text-white text-xl font-black h-fit py-2 rounded-xl font-Roboto">
          ENQUIRE NOW
        </Button>
      </div>
      <Row wrap gutter={[16,16]} className="mt-3 border-b-4 border-b-black lg:pb-16 pb-10">
        <Col xl={10} md={24} sm={24} xs={24}>
          <img src={MainImage} alt="" className="lg:h-full h-auto w-full max-w-full " />
        </Col>
        <Col xl={14} md={24} sm={24} xs={24}>
          <div className="flex justify-center flex-col items-center h-full sm:ps-4">
          <p className="font-Roboto text-[23px] font-light whitespace-normal">
            We're a technology-focused logistics company specializing in
            last-mile delivery, dedicated to bringing happiness to both our
            partners and individuals. Our aim is to empower them in effortlessly
            handling their respective business and personal shipments.
          </p>
          <p className="font-Roboto text-[23px] font-light whitespace-normal">
            Our tailored services cater to the needs of both small and large
            e-commerce enterprises seeking a dependable final-mile courier
            partner. We provide comprehensive logistics solutions designed to
            bolster businesses. Our goal is to equip e-commerce ventures with
            swift next-day delivery, same-day and specialized express
            deliveries, supported by cutting-edge technology. This includes
            real-time order tracking, electronic proof of delivery, and dynamic
            routing, ensuring rapid and efficient delivery services.
          </p>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Section2;
