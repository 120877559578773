import React from "react";
import image from "../../../images/publicWebsites/Landing/Section6.png";
import { Row, Col, Button } from "antd";
import playStore from "../../../images/publicWebsites/playStore.png";
import appStore from "../../../images/publicWebsites/AppStore.png";
import mobile from "../../../images/publicWebsites/Mobile.png";
type Props = {};

const OrderPickUp = (props: Props) => {
  return (
    <section className="container mx-auto mt-5 lg:pb-20 pb-10 border-b-2 border-[#C6C6C6]">
      <Row gutter={[40, 24]}>
      <Col xl={6} lg={6} md={24} sm={24} xs={24} className="block lg:hidden">
          <div className="flex justify-center">
          <img src={image} alt="" className="h-full max-w-full" />
          </div>
        </Col>
        <Col xl={16} lg={12} md={24} sm={24} xs={24}>
          <div className="flex flex-col justify-center h-full items-start">
            <h1 className="text-6xl font-black font-Roboto">
              Order, pick, <br className="hidden lg:block" />
              drop,{" "}
              <span className="text-6xl font-black font-Roboto text-FA0001">
                repeat.
              </span>
            </h1>
            <p className="font-light text-black font-Roboto text-3xl my-4 leading-[47px] xl:w-9/12">
              We spread joy from door to door by operating at the speed of smart
              to deliver documents and other parcels to your desired location
              with our efficient pick-up and drop solutions. All you need to do
              is schedule your deliveries on the TransitNow app and get timely
              status updates to conveniently track your order.
            </p>
            <Button className="rounded-xl bg-FA0001 ant-custom-btn text-white font-black text-3xl leading-[47px] h-20 px-5 mt-2 ">
              Download Now
            </Button>{" "}
            <div className="mt-4">
              <div className="flex flex-wrap">
                <a href="#">
                  <img
                    src={playStore}
                    className="h-[52px] me-3 w-[147px]"
                    alt=""
                  />
                </a>
                <a href="#">
                  <img src={appStore} className="h-[52px]  w-full" alt="" />
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={6} lg={6} md={24} sm={24} xs={24} className="hidden xl:block">
          <img src={image} alt="" className="h-full max-w-full" />
        </Col>
      </Row>
    </section>
  );
};

export default OrderPickUp;
