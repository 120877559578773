import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../images/LOGO/LOGO.png";
import mobileLogo from "../../../images/LOGO/LOGO.png";
import "./login.scss";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import {
  loginSubmit,
  onChangeEmail,
  onChangePassword,
  onChangeShowPassword,
} from "../../../actions/login";
import { Button } from "antd";
type Props = {};

const Login = (props: Props) => {
  const navigate = useNavigate();
  const dispatch: Dispatch | any = useDispatch();
  const login = useSelector((state: any) => state.login);
  const {
    showPassword,
    email,
    password,
    serverError,
    passwordError,
    redirect,
  } = login;

  const [error, setError] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (redirect) {
      navigate("/admin/dashboard");
    } else {
      navigate("/admin/");
    }
  }, [redirect, navigate]);

  const validation = () => {
    if (email === "" && password === "") {
      setError({
        email: "Please enter usernmae.",
        password: "Please enter password.",
      });
      return false;
    } else if (passwordError) {
      setError({ ...error, password: passwordError });
      return false;
    } else if (password === "") {
      setError({ ...error, password: "Please enter password." });
      return false;
    }

    return true;
  };
const [loading,setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(false)
    setError({ ...error, password: passwordError });
  }, [passwordError]);
  const submit = (e: any) => {
    e.preventDefault();
    setIsLoading(true)
    if (validation()) {
      dispatch(loginSubmit());
    }else{
      setIsLoading(false)
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError({ ...error, email: "" }); // Clear email error
    dispatch(onChangeEmail(e.target.value));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError({ ...error, password: "" }); // Clear password error
    dispatch(onChangePassword(e.target.value));
  };

  return (
    <section className="vh-100">
      <div className="container-fluid h-100">
        <div className="row d-flex justify-content-center align-items-center h-100 white">
          <div className="col-md-6">
            <img
              src={Logo}
              className="img-fluid d-none d-md-block"
              alt="Sample"
            />
            <img
              src={mobileLogo}
              className="img-fluid d-block d-md-none"
              alt="Sample"
            />
          </div>
          <div className="col-md-6 h-100 brand-color">
            <div className="auth-wrapper">
              <div className="auth-inner">
                <form onSubmit={submit}>
                  <h3>Sign In</h3>
                  <div className="mb-3">
                    <label>Username</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter username"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {error.email && (
                      <div className="errorMsg mt-2">
                        <p>{error.email}</p>
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label>Password</label>
                    <div className="show-password">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Enter password"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                      <span onClick={() => dispatch(onChangeShowPassword())}>
                        <i
                          className={`fas ${
                            !showPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                        />
                      </span>
                    </div>
                    <span className="flex justify-around items-center"></span>
                    {/* <span
                      className="pe-auto forgot-password cursor-pointer"
                      onClick={() => navigate("/admin/reset")}
                    >
                      Forgot Password?
                    </span> */}
                    {error.password && (
                      <div className="errorMsg mt-2">
                        <p>{error.password}</p>
                      </div>
                    )}
                    {serverError && (
                      <div className="errorMsg mt-2">
                        <p>{serverError}</p>
                      </div>
                    )}
                  </div>
                  <div className="mb-3"></div>
                  <div className="d-grid mt-4">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit btn btn-primary"
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </div>
                  {/* <p className="forgot-password text-right">
                    Not a member?{" "}
                    <span
                      onClick={() => navigate("/admin/sign-up")}
                      className="pe-auto cursor-pointer"
                    >
                      Register
                    </span>
                  </p> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
