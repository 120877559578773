export const _baseURL = `${'https://ridewebapi20240515124810.azurewebsites.net'}`;
export const _rolesURL = `${_baseURL}/api/Role`
export const __login = `${_baseURL}/api/Login`
export const _usersURL = `${_baseURL}/api/User`
export const __vechileListURL = `${_baseURL}/api/Vehicle/GetAllVehicle`
export const __vechileMappingURL = `${_baseURL}/api/VehicleMapping`
export const ___driverFormURL = `${_baseURL}/api/Driver/DriverRegistration`
export const __customerURL = `${_baseURL}/api/Customer/GetAllCustomers`
export const __manageDriversURL = `${_baseURL}/api/Driver/GetAllDrivers`
export const __manageFileType = `${_baseURL}/api/Driver`
export const __getApprovedDriverList = `${_baseURL}/api/Driver/DriverApprovedList`
export const __DriverFormHandling = `${_baseURL}/api/Driver/DriverApproval`
export const __changePassword = `${_baseURL}/api/ChangePwd`
