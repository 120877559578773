import { Col, Row } from "antd";
import React from "react";
import icon1 from "../../../../images/Driver/like.svg";
import icon2 from "../../../../images/Driver/coins.svg";
import icon3 from "../../../../images/Driver/smile.svg";

type Props = {};

const ChooseUs = (props: Props) => {
  const ChooseUsData = [
    {
      image: icon1,
      title: "Regular Trips",
      desc: "With our growing presence across multiple cities, we always have our hands full! This means you will never run out of trips.",
    },
    {
      image: icon2,
      title: "Better Earning",
      desc: "Earn more by partnering with the best! Regular trips and efficient service can grow your earnings!",
    },
    {
      image: icon3,
      title: "On-Time Payment",
      desc: "Be assured to receive all payments on time & get the best in class support, when you attach mini truck with Porter.",
    },
  ];

  return (
    <section className="bg-[#F5F5F5]">
      <div className="container mx-auto py-11 lg:py-32">
        <p className=" text-4xl lg:text-5xl text-black font-bold font-Poppins text-center mb-12">
          Why Choose Us
        </p>
        <Row gutter={[32, 32]}>
          {ChooseUsData?.map((data, index) => (
            <Col lg={8} sm-={24} key={index}>
              <div className="h-64 shadow-lg rounded-lg bg-white">
                <div className="flex justify-around h-full p-4 flex-col">
                  <img src={data.image} alt="" className="h-14 w-14 mb-3" />
                  <p className="font-Poppins font-bold text-black text-3xl">
                    {data.title}
                  </p>
                  <p className="font-Poppins font-light text-black text-sm">
                    {data.desc}
                  </p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
};

export default ChooseUs;
