import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "./registeration.scss";
import Logo from "../../../images/LOGO/LOGO.jpg";
import mobileLogo from "../../../images/LOGO/LOGO.png";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../config/firebase";

type Props = {};

const Registeration = (props: Props) => {
  const navigate = useNavigate();
  const [data,setData] = useState({
    name:'',
    email:'',
    password:'',
    confirmPassword:'',
  })
  const handleChange = (event:any) => {
    event.persist();
    setData(data => ({ ...data, [event.target.name]: event.target.value }));
  }
  const submit= async () => {
    try {
      const user:any = await createUserWithEmailAndPassword(auth,data.email,data.password)
      localStorage.setItem('user',JSON.stringify(user))
    } catch (error:any) {
      console.log(error.message)
    }
  }
  return (
    <section className="vh-100">
      <div className="container-fluid h-100">
        <div className="row d-flex justify-content-center align-items-center h-100 white">
          <div className="col-md-6">
          <img src={Logo} className="img-fluid d-none d-md-block" alt="Sample" />
            <img src={mobileLogo} className="img-fluid d-block d-md-none" alt="Sample" />
          </div>
          <div className="col-md-6 h-100 brand-color">
            <div className="auth-wrapper">
              <div className="auth-inner">
                <form onSubmit={submit}>
                  <h3>Sign Up</h3>
                  <div className="mb-3">
                    <label>Full name</label>
                    <input
                      type="text"
                      className="form-control"
                      name='name'
                      placeholder="Full name"
                      value={data.name || ''}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="mb-3">
                    <label>Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      name='email'
                      placeholder="Enter email"
                      value={data.email || ''}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="mb-3">
                    <label>Mobile number</label>
                    <PhoneInput
                      placeholder="Enter mobile number"
                      country={"us"}
                      className="form-control"
                      value={""}
                      onChange={() => null}
                    />
                  </div>
                  <div className="mb-3">
                    <label>Password</label>
                    <div className="show-password">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter password"
                      name='password'
                      value={data.password || ''}
                      onChange={(e) => handleChange(e)}
                    />
                    <span className="">
                        <i className="fas fa-eye-slash"></i>
                      </span>
                    </div>
                  </div>
                  <div className="mb-3">
                  
                    <label>Confirm Password</label>
                    <div className="show-password">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm password"
                      value={data.name || ''}
                      onChange={(e) => handleChange(e)}
                    />
                    <span className="">
                        <i className="fas fa-eye-slash"></i>
                      </span>
                    </div>
                  </div>
                  <div className="d-grid mt-5">
                    <button type="submit" className="btn btn-primary submit">
                      Sign Up
                    </button>
                  </div>
                  <p className="forgot-password text-right">
                    Already registered{" "}
                    <span onClick={() => navigate("/admin/")} className="pe-auto cursor-pointer">
                      sign in?
                    </span>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Registeration;
