import React,{useState,useEffect} from "react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import HamBurger from "../../images/menu-button-of-three-horizontal-lines (2).png";
import moment from 'moment';
import alert from '../../images/active.png'
import UserProfileDropDown from "./UserProfileDropDown";

type Props = {
  /**
   * Allows the parent component to modify the state when the
   * menu button is clicked.
   */
  onMenuButtonClick(): void;
};

const HeaderBar = ({ setCollapsed, collapsed, onMenuButtonClick }: any) => {
    const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment());
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, []);
  return (
    <nav
      className={classNames({
        "bg-white text-black": true, // colors
        "flex items-center": true, // layout
        "w-screen md:w-full sticky z-10 px-4 shadow-lg h-24 top-0 ": true, //positioning & styling
      })}
    >
      <div className="flex justify-between w-full items-center">
        <div className="font-bold">
          {" "}
          <button
            className={classNames({
              " place-content-center md:grid hidden": true, // position // colors
              "w-10 h-10 rounded-md text-red bg-white": true, // shape
            })}
            onClick={() => setCollapsed(!collapsed)}
          >
            <img src={HamBurger} alt="" className="w-5 h-5" />
          </button>
        </div>
        <div className="lg:w-6/12 xl:w-5/12 2xl:w-[40%] lg:block hidden">
            <div className="flex md:justify-between md:self-center">
                <div className="">
                <p className="font-Poppins font-medium text-xl">{`${currentTime.format('MMMM Do YYYY | h:mm:ss a')}`}</p>
                </div>
                <div className="">
                    {/* <img src={alert} alt='' className="w-5 h-5" /> */}
                </div>
                <UserProfileDropDown/>

            </div>
        </div>
      </div>

      <button
        className={classNames({
          "grid place-content-center md:hidden": true, // position // colors
          "w-10 h-10 rounded-md text-red bg-white": true, // shape
        })}
        onClick={onMenuButtonClick}
      >
        <Bars3Icon className="w-5 h-5" />
      </button>
    </nav>
  );
};

export default HeaderBar;
