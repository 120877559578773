import { __login } from "../constants/api";
import { handleResponse } from "./serviceInterceptor";

const headers = {
  "Content-Type": "application/json",
  "Content" : "include"
};


export const loginAPI = async (userId, password) => {
  try {
    const response = await fetch(__login, {
      method: "POST",
      credentials:"include",
      body: JSON.stringify({ userId, password }),
      headers,
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error in login:", error);
    throw error;
  }
};
