import {
  LOGIN_REDIRECT,
  SHOW_CONFIRM_PASSWORD,
  SHOW_PASSWORD,
  USER_CONFIREM_PASSWORD_ERROR,
  USER_CONFIRM_PASSWORD,
  USER_EMAIL_ERROR,
  USER_EMAIL_ID,
  USER_EMAIL_SERVER_ERROR,
  USER_FULL_NAME,
  USER_MOBILE_NUMBER,
  USER_MOBILE_NUMBER_ERROR,
  USER_NAME_ERROR,
  USER_PASSWORD,
  USER_PASSWORD_ERROR,
  USER_SERVER_ERROR,
} from "../constants/redux";

type State = {
  email: string;
  password: string;
  mobile: string | number;
  confirmPassword: string;
  name: string;
  emailError: string;
  passwordError: string;
  Userredirect: boolean;
  nameError: string;
  confirmPasswordError: string;
  serverError: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
};
const initialState: State = {
  email: "",
  password: "",
  mobile: "",
  confirmPassword: "",
  name: "",
  emailError: "",
  passwordError: "",
  Userredirect: false,
  nameError: "",
  confirmPasswordError: "",
  serverError: "",
  showPassword: false,
  showConfirmPassword: false,
};

export const registerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case USER_EMAIL_ID:
      return {
        ...state,
        email: action.email,
      };
    case USER_FULL_NAME:
      return {
        ...state,
        name: action.name,
      };
    case USER_PASSWORD:
      return {
        ...state,
        password: action.password,
      };
    case USER_CONFIRM_PASSWORD:
      return {
        ...state,
        confirmPassword: action.confirmPassword,
      };
    case SHOW_PASSWORD:
      return {
        ...state,
        showPassword: action.showPassword,
      };
    case SHOW_CONFIRM_PASSWORD:
      return {
        ...state,
        showConfirmPassword: action.showConfirmPassword,
      };
    case USER_EMAIL_ERROR:
      return {
        ...state,
        emailError: action.emailError,
      };
    case USER_NAME_ERROR:
      return {
        ...state,
        nameError: action.nameError,
      };
    case USER_PASSWORD_ERROR:
      return {
        ...state,
        passwordError: action.passwordError,
      };
    case USER_CONFIREM_PASSWORD_ERROR:
      return {
        ...state,
        confirmPasswordError: action.confirmPasswordError,
      };
    case USER_SERVER_ERROR:
      return {
        ...state,
        serverError: action.serverError,
      };
    case USER_MOBILE_NUMBER:
      return {
        ...state, mobile : action.mobile
      }
    case 'USER_REDIRECT':
      return {
        ...state,
        Userredirect: action.redirect,
      };
    case USER_EMAIL_SERVER_ERROR:
      return {...state, emailError : action.emailError}
    case 'USER_RESET' :
      return initialState
    default:
      return state;
  }
};
