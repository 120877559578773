import React, { useEffect, useState } from "react";
import { getAllCustomer } from "../../../api/cutomerAPI";
import { Avatar, Table } from "antd";
import { getRandomColor } from "../../../config/randomColor";
import "./customer.scss";
import { CustomerData, TableParams } from "../../../interface/interface";



interface CustomerProps {}

const Customer: React.FC<CustomerProps> = () => {
  const [customerData, setCustomerData] = useState<CustomerData[]>([]);
  const[loading,setLoading] = useState(true)
  const [tableParams, setTableParams] = useState<TableParams|any>({
    pagination: {
      current: 1,
      pageSize: 10,
      position: "bottomRight",
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllCustomer();
        console.log(typeof response);
        if(response){
          setLoading(false)
        }
        setCustomerData(response);
      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setTableParams((prevTableParams:any) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        total: customerData.length,
      },
    }));
  }, [customerData]);

  const columns = [
    {
      title: "User",
      dataIndex: "user",
      sorter: false,
      render: (text: any, record: CustomerData) => (
        <div className="flex">
          <div>
            {record.profilePhoto ? (
              <Avatar src={record.profilePhoto} alt={record.name} />
            ) : (
              <Avatar
                style={{ backgroundColor: getRandomColor() }}
                className="custom-avatar"
                size="large"
              >
                <span>{record.name.charAt(0).toUpperCase()}</span>
              </Avatar>
            )}
          </div>
          <div className="ms-3">
            <p className="mb-0 text-lg">
              <strong>{record.name}</strong>
            </p>
            <p className="text-sm">{record.email}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "contactNo",
    },
    {
      title: "Language",
      dataIndex: "language",
    },
    {
      title: "Address",
      dataIndex: "customerAddressDetails",
      render: (text: any, record: CustomerData) => (
        <p className="mb-0">
          {record.customerAddressDetails && record.customerAddressDetails.length > 0
            ? `${record.customerAddressDetails[0].address1} ${record.customerAddressDetails[0].city} ${record.customerAddressDetails[0].state} ${record.customerAddressDetails[0].pinCode}`
            : "N/A"}
        </p>
      ),
    },
    {
      title: "Reward Points",
      dataIndex: "rewardPointBalance",
    },
  ];

  return (
    <section className="py-10 px-2 customer">
      <div className="flex justify-between">
        <p className="text-3xl font-bold">Customer Details</p>
      </div>
      <div className="py-3">
        <Table
          size="large"
          className="transit-custom-table"
          columns={columns}
          dataSource={customerData}
          loading={loading}
          pagination={customerData.length > 10 && tableParams.pagination}
        />
      </div>
    </section>
  );
};

export default Customer;
