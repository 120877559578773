import { Col, Row, Button } from "antd";
import React from "react";
import Android from "../../../images/publicWebsites/Landing/Section7.png";
import playStore from "../../../images/publicWebsites/playStore.png";
import appStore from "../../../images/publicWebsites/AppStore.png";
import mobile from "../../../images/publicWebsites/Mobile.png";
import { Link } from "react-router-dom";

type Props = {};

const Section7 = (props: Props) => {
  return (
    <section className="container mx-auto py-10">
      <div className="flex justify-between xl:items-end items-center lg:flex-nowrap flex-wrap mb-4 sm:mb-14">
        <div>
          <h1 className="font-Roboto font-extrabold text-4xl lg:text-6xl lg:leading-[5rem]">
            Curious to know more?
            <p className="text-FA0001 font-Roboto font-extrabold text-4xl lg:text-6xl lg:leading-[4rem]">
              We’ve got all the answers!
            </p>
          </h1>
        </div>
        <Button className="bg-FA0001 ant-custom-btn text-white text-3xl font-black h-fit py-2 rounded-xl font-Roboto">
          Learn more
        </Button>
      </div>
      <div className="px-2 sm:px-10">
        <div className="bg-[#F8F5F5] rounded-2xl drop-shadow h-full xl:h-[429px]">
          <Row className="pt-4 h-full xl:h-[429px] xl:relative">
            <Col lg={14} offset={2}>
              <div className="py-8 flex flex-col justify-around h-full">
                <div className="mb-3">
                  <h1 className="font-semibold text-[40px] leading-[48px] text-black mb-0">
                    Get the{" "}
                    <span className="px-1 text-[#FA0001] font-semibold text-[40px] leading-[48px]">
                      Driver
                    </span>{" "}
                    app
                  </h1>
                  <span className="text-sm font-normal text-[#7A7A7A]">
                    We will send you a link, open it on your phone to download
                    the app
                  </span>
                </div>

                <div className="group lg:w-[65%] w-full mb-3">
                  <div className="relative w-full flex items-center">
                    <input
                      id="combined"
                      type="text"
                      placeholder="Enter Phone Number"
                      className="peer relative h-16 r w-full rounded-full text-base pl-12 pr-20 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out "
                    />
                    <span className="material-symbols-outlined ms-2 absolute left-2 transition-all duration-200 ease-in-out group-focus-within:text-blue-400">
                      <img src={mobile} alt="" />
                    </span>
                    <button className="absolute lg:right-7 right-2 h-10 lg:w-36 w-10 rounded-full bg-[#0C6A0A] text-base font-bold text-white ">
                      Send app link
                    </button>
                  </div>
                </div>

                <div>
                  <p className="text-base text-black mb-2">Download app from</p>
                  <div className="flex flex-wrap">
                    <a href="#">
                      <img src={playStore} className="h-[52px] me-3 w-full" alt="" />
                    </a>
                    <a href="#">
                      <img src={appStore} className="h-[52px]  w-full" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <img className="lg:ms-4 w-[481px] h-[472px] xl:absolute xl:top-[-49px]" src={Android} alt="" />
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default Section7;
