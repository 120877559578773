import {
  __vechileListURL,
  __vechileMappingURL,
  _baseURL,
} from "../constants/api";
import { VEchileMappingList, VechileList } from "../interface/interface";
import {
  ServiceInterceptorResponse,
  serviceInterceptor,
} from "./serviceInterceptor";

export const getVechileList = (): Promise<
  ServiceInterceptorResponse<VechileList[]>
> => serviceInterceptor(__vechileListURL, "GET");

export const getVehicleMappingDetails = () =>
  serviceInterceptor(`${__vechileMappingURL}/GetAllVehicleMapping`, "GET");

export const insertPricingList = (
  data: any
): Promise<ServiceInterceptorResponse<VEchileMappingList[]>> =>
  serviceInterceptor(`${__vechileMappingURL}/VehicleMapping`, "POST", data);

export const deleteVechilePricingRecord = (
  record: VEchileMappingList[] | any
): Promise<ServiceInterceptorResponse<VEchileMappingList[] | any>> =>
  serviceInterceptor(
    `${__vechileMappingURL}/RemoveVehicleMapping/${record?.id}`,
    "DELETE",
    record
  );

export const getParticularPriceDetails = (
  id: number
): Promise<ServiceInterceptorResponse<VEchileMappingList[] | any>> =>
  serviceInterceptor(
    `${__vechileMappingURL}/GetVehicleMappingById/${id}`,
    "GET"
  );

export const updateParticularPricingDetails = (
  id: number,
  data: VEchileMappingList[]
): Promise<ServiceInterceptorResponse<VEchileMappingList[]>> =>
  serviceInterceptor(
    `${__vechileMappingURL}/UpdateVehicleMapping/${id}`,
    "PUT",
    data
  );

export const getALLservice = (): Promise<ServiceInterceptorResponse<any>> =>
  serviceInterceptor(`${_baseURL}/api/Service/GetAllServicescategory`, "GET");
