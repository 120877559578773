import { signOut } from 'firebase/auth'
import React from 'react'
import { auth } from '../../../config/firebase'
import { useDispatch } from 'react-redux'
import { Row } from 'antd'
import TotalEarnings from './TotalEarnings/TotalEarnings'
import '../../../scss/Dashboard.scss'
import TotalRides from './TotalRides/TotalRide'
import TotalDrivers from './TotalDrivers/TotalDrivers'
import TotalCompanies from './TotalCompanies/TotalCompanies'
import TodayEarnings from './TodayEarnings/TodayEarnings'
import TodayRides from './TodayRides/TodayRides'
import CompletedRides from './CompletedRides/CompletedRides'
import CancellRides from './CancelledRides/CancelledRides'
import RecentRides from './RecentRides/RecentRides'
import TopDrivers from './TopDrivers/TopDrivers'

type Props = {}

const Dashboard = (props: Props) => {
  const dispatch = useDispatch()
  return (
    <div className='md:px-4 md:py-5'>
      <p className='font-extrabold text-3xl '>Dashboard <span className='font-light text-xl'>Control panel</span></p>
      <Row className='w-full' gutter={[24, 32]} wrap>
        <TotalEarnings/>
        <TotalRides/>
        <TotalDrivers/>
        <TotalCompanies/>
        <TodayEarnings/>
        <TodayRides/>
        <CompletedRides/>
        <CancellRides/>
        <RecentRides/>
        <TopDrivers/>
      </Row>
    </div>
  )
}

export default Dashboard