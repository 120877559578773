import { Button, Divider, Form, Input, InputNumber, Select } from "antd";
import React, { useEffect, useState } from "react";
import "../users.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../users.scss";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import {
  CreateUsers,
  getParticularUser,
  updateParticularUser,
} from "../../../../../api/usersAPI";
import { getAllRoles } from "../../../../../api/rolesAPI";
type Props = {};

const CreateUser = (props: Props) => {
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const navigate: any = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const { id } = useParams();
  const [data, setData] = useState<any>({});

  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState<any>();
  useEffect(() => {
    (async () => {
      const __roles:any = await getAllRoles();
      const roles: { id: number; roleName: string }[]|any = __roles.map(
        (obj: any) => ({
          id: obj.id,
          roleName: obj.roleName,
        })
      );

      // Extract unique role names with IDs
      const uniqueRoles: { id: number; roleName: string }[]|any = Array.from(
        new Map(roles.map((role:any) => [role.roleName, role]))
      ).map(([roleName, role]) => role);
      // console.log(uniqueRoles)
      setRoles(uniqueRoles);
    })();
  }, []);

  useEffect(() => {
    const fetchData: any = async () => {
      try {
        const __data = await getParticularUser(id);

        if (__data || Array.isArray(__data)) {
          setData(__data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    // Only trigger the fetch data logic when the pathname includes "edit"
    if (location.pathname.includes("edit")) {
      fetchData();
    }
  }, [id, location.pathname]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email_Id: data?.email_Id,
        userId: data?.userId,
        mobile_Number: data?.mobile_Number,
        password: data?.password,
        role_Id: data?.role_Id,
        isActive: data.isActive,
        // Status: data.Status === 0 ? true:false,
      });
    }
  }, [id, form, data]);

  const onFinish = async (values: any) => {
    if (!pathname.includes("/admin/users/edit/")) {
      console.log("first", values);
      const __data = await CreateUsers(values);
      if (__data) {
        navigate(-1);
      }
    } else {
      const updated_value = { ...data, ...values };
      const __data = await updateParticularUser(id, updated_value);
      if (__data) {
        navigate(-1);
      }
      console.log(values);
    }
  };
  const { pathname } = location;

  // Define the title based on the pathname
  let pageTitle = "Create User";
  let buttonText = "Submit";

  if (pathname.includes("/admin/users/edit/")) {
    pageTitle = "Edit Users";
    buttonText = "Update"; // Change this to the desired title for the edit page
  }
  return (
    <section className="py-0 px-2 create-users">
      <div className="flex justify-between">
        <p className="text-3xl font-extrabold font-Poppins" >{pageTitle}</p>
        <Button
          type="primary"
          onClick={() => navigate(-1)}
          className="users-button w-32 !h-11"
          size="large"
          icon={<i className="fa-solid fa-arrow-left"></i>}
        >
          Go Back
        </Button>
      </div>
      <Divider />
      <div className="p-8 rounded border border-gray-200">
        <Form
          size="large"
          name="userForm"
          form={form}
          colon={false}
          requiredMark="optional"
          layout="vertical"
          className="mt-  grid lg:grid-cols-2 gap-4"
          // labelAlign="left"
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="User Name"
            name="userId"
            className="text-sm text-gray-700 block mb-1  font-medium"
            rules={[
              {
                required: true,
                message: "The user name is required.",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                const value = e.target.value;
              }}
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            className="!text-base"
            rules={[
              {
                required: true,
                message: "The password is required.",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters long.",
              },
              {
                pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
                message:
                  "Password must contain at least one number and one special character.",
              },
            ]}
          >
            <Input.Password
              onChange={(e) => {
                const value = e.target.value;
              }}
              size="large"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
                message: "The  First Name is required.",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                const value = e.target.value;
              }}
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
                message: "The Last Name is required.",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                const value = e.target.value;
              }}
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="Email Id"
            name="email_Id"
            rules={[
              {
                required: true,
                message: "The Email Id is required.",
              },
              {
                type: "email",
                message: "Please enter a valid email address.",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                const value = e.target.value;
              }}
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name="mobile_Number"
            rules={[
              {
                required: true,
                message: "The Mobile Number is required.",
              },
              {
                pattern: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit phone number.",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                const value = e.target.value;
              }}
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role_Id"
            rules={[
              {
                required: true,
                message: "The Role Id is required.",
              },
            ]}
          >
            {/* <Input
              onChange={(e) => {
                const value = e.target.value;
              }}
              size="large"
            /> */}

            <Select>
              {roles?.map(
                (
                  role: { id: number; roleName: string }[] | any,
                  index: number
                ) => (
                  <Select.Option key={index} value={role.id}>
                    {role.roleName}
                  </Select.Option>
                )
              )}
              {/* <Select.Option value={1}>Admin</Select.Option>
              <Select.Option value={2}>SuperAdmin</Select.Option> */}
            </Select>
          </Form.Item>
          <Form.Item
            label="Status"
            name="isActive"
            rules={[
              {
                required: true,
                message: "The status is required.",
              },
            ]}
          >
            <Select>              
              <Select.Option value={true}>Active</Select.Option>
              <Select.Option value={false}>In-Active</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item className="p-2 flex">
            <div className="w-2/2 flex justify-end">
              <Button
                size="large"
                htmlType="submit"
                className="bg-green-500 w-32 !h-11 text-white p-2 ml-6 hover:!border-green-500 hover:border-1"
              >
                {buttonText}
              </Button>
              <Button
                size="large"
                htmlType="reset"
                className="bg-gray-400 ms-4 w-32 !h-11  p-2 text-white hover:!border-gray-400 hover:border-1"
              >
                Clear
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
};

export default CreateUser;
