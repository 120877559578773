import { Col } from "antd";
import React from "react";

type Props = {};

const TopDrivers = (props: Props) => {
  return (
    <Col lg={24} xl={10}>
      <div className="dashboardSubPannels table py-3 ">
        <div className="px-4">
          <p className="text-black font-bold text-xl">Top Drivers</p>
        </div>
        <hr className="text-[#C5C1C1]" />
      </div>
    </Col>
  );
};

export default TopDrivers;
