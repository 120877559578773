import { Button, Col, Modal, Row } from "antd";
import React, { useState } from "react";
import BannerImage from "../../../images/publicWebsites/customer/customerLanding.png";
import { useNavigate } from "react-router-dom";

type Props = {};

const Banner = (props: Props) => {
  const navigate = useNavigate()
  return (
    <section className="sectionOne container mx-auto border-b-2 border-[#C6C6C6] pb-4">
      <Row gutter={[40, 16]}>
        <Col lg={13} sm={24}>
          <img src={BannerImage} alt="" className="h-auto max-w-full" />
        </Col>
        <Col lg={11} sm={24}>
          <div className="flex flex-col items-baseline justify-center h-full ">
            <h1 className="font-Roboto text-FA0001 font-black sectionOneText ">
             Fastest Delivery with <br/> Easy Pick up
            </h1>
            <p className="font-black text-2xl my-2 font-Roboto w-full">Enjoy Same Day Safe Delivery with us</p>
            <Button className="bg-FA0001 ant-custom-btn border-FA0001 font-black text-white text-xl md:text-2xl px-4 uppercase rounded-xl h-14 mt-7">
              Deliver your parcel now
            </Button>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Banner;
