import { Button, Col, Modal, Row } from "antd";
import React, { useState } from "react";
import BannerImage from "../../../images/publicWebsites/Landing/Section1.png";
import secondBanner from "../../../images/publicWebsites/Landing/Second-banner.png";
import { useNavigate } from "react-router-dom";
import playStore from "../../../images/publicWebsites/playStore.png";
import appStore from "../../../images/publicWebsites/AppStore.png";
import Logo from '../../../images/LOGO/LOGO 5.png'
type Props = {};

const Section1 = (props: Props) => {
  const navigate = useNavigate();
  return (
    <section className="sectionOne container mx-auto">
      <div className="border-b-4 border-black pb-4">
        <Row gutter={[40, 16]}>
          <Col lg={{ span: 13, push: 11 }} sm={24}>
            <img src={BannerImage} alt="" className="h-auto max-w-full" />
          </Col>
          <Col lg={{ span: 11, pull: 13 }} sm={24}>
            <div className="flex flex-col items-baseline justify-center h-full ">
              <h1 className="font-Roboto font-black sectionOneText ">
                We have{" "}
                <span className="text-FA0001 font-black ">Faster Delivery</span>{" "}
                than others.
              </h1>
              <Button className="bg-FA0001 ant-custom-btn border-FA0001 font-black text-white text-2xl px-4 rounded-xl h-14 mt-7">
                Book your Shipment
              </Button>
            </div>
          </Col>
        </Row>
        {/* <Row className="mt-3 mb-4" justify="center" gutter={[40, 16]}>
        <Col lg={12} sm={24} xs={24} className="customers rounded-md">
          <div className=" customer-sm  py-4 lg:px-0 px-3">
            <p className="font-Roboto font-extrabold text-4xl text-black">
              For Customers
            </p>
            <p className="text-xl font-light whitespace-normal">
              Either delivering a package to a specified destination or
              arranging immediate transportation for individuals to their
              desired location, we caters to your urgent needs with efficiency
              and convenience.
            </p>
            <div className="mt-4 mb-1 sm:w-[70%] flex justify-between flex-wrap">
              <Button className="text-white bg-FA0001 ant-custom-btn border-FA0001 text-xl font-extrabold w-full h-100 sm:h-[39px] sm:!w-[196px] rounded-none">
                SEND PACKAGE
              </Button>
              <Button className="text-white bg-FA0001 ant-custom-btn border-FA0001 text-xl font-extrabold  w-full h-100 sm:h-[39px] sm:!w-[196px] rounded-none">
                RIDE NOW
              </Button>
            </div>
          </div>
        </Col>
        <Col
          lg={8}
          sm={24}
          xs={24}
          className="drivers rounded-md md:px-3 lg:ms-5"
        >
          <div className="py-4 lg:px-0 px-3 driver-sm">
            <p className="font-Roboto font-extrabold text-4xl text-black">
              For Drivers
            </p>
            <p className="text-xl font-light whitespace-normal">
              Some of the best drivers work with us because we offer flexible
              hours, great pay and fair treatment. Apply in just 10 minutes.
            </p>
            <Button
              className="text-white bg-FA0001 ant-custom-btn border-FA0001 text-xl font-extrabold h-[39px] w-[196px] rounded-none mt-2 "
              onClick={() => navigate('/become-a-rider')}
            >
              GET STARTED
            </Button>
          </div>
        </Col>
      </Row> */}
      </div>
      <div className="mt-5">
        <p className="text-6xl text-black mb-5 font-black text-center font-Roboto ">
          Latest Offers & Updates
        </p>
        <div>
          <img src={secondBanner} alt={""} className="h-auto max-w-full" />
        </div>
      </div>
      <div className="mt-5">
        <p className="text-6xl text-FA0001 mb-5 font-black text-center font-Roboto ">
          Download our App Now
        </p>
        <Row gutter={[40, 16]}>
          <Col lg={12} md={24}>
            <div className="bg-[#F5F5F5] rounded-xl flex flex-col justify-evenly shadow-md p-4 h-full md:h-[370px]">
              <p className="font-Roboto text-4xl font-black text-center">
                Personal & Business Delivery App
              </p>
              <div className="flex justify-center">
                <img src={Logo} alt={'Logo'} className="h-auto max-w-full"/>
              </div>
              <div className="mt-4">
                <div className="flex flex-wrap justify-center">
                  <a href="#">
                    <img
                      src={playStore}
                      className="h-[52px] me-3 w-[147px]"
                      alt=""
                    />
                  </a>
                  <a href="#">
                    <img src={appStore} className="h-[52px]  w-full" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={12} md={24}>
            <div className="bg-[#F5F5F5] flex flex-col justify-evenly rounded-xl shadow-md p-4 h-full md:h-[370px]">
              <p className="font-Roboto text-4xl font-black text-center">
                Driver App
              </p>
              <div className="flex justify-center">
              <img src={Logo} alt={'Logo'} className="h-auto max-w-full"/>
              </div>
              <div className="mt-4">
                <div className="flex flex-wrap justify-center">
                  <a href="#">
                    <img
                      src={playStore}
                      className="h-[52px] me-3 w-[147px]"
                      alt=""
                    />
                  </a>
                  <a href="#">
                    <img src={appStore} className="h-[52px]  w-full" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Section1;
