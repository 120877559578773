import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './scss/index.scss'
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter as Router} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
     <Router>
      <App/>
      <ToastContainer />
     </Router>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
