import { combineReducers } from "redux";
import { LoginReducer } from "./login";
import { registerReducer } from "./registeration";
import { rolesListReducer } from "./rolePermission";
import { usersListReducer } from "./userList";
import { vehicleReducer } from "./vechiles";
import { driverReducer } from "./driver";
import { serviceReducer } from "./service";
import { modalReducer } from "./modalReducer";

const rootReducer = combineReducers({
    login : LoginReducer,
    register : registerReducer,
    roles : rolesListReducer,
    users : usersListReducer,
    vehicles : vehicleReducer,
    driver:driverReducer,
    service: serviceReducer,
    modal : modalReducer
});

export default rootReducer;