import React from "react";
import Banner from "./Banner";
import DriverAppNow from "./DriverAppNow";
import Section8 from "../Landing Page/Section8";
import OrderPickUp from "./OrderPickUp";
import WhyWe from "./WhyWe";

type Props = {};

const CustomerIndex = (props: Props) => {
  return (
    <>
      <Banner />
      <WhyWe/>
      <OrderPickUp/>
      <Section8 />
      <DriverAppNow />
    </>
  );
};

export default CustomerIndex;
