import { DELETE_USER_SUCCESS, USER_LIST_LOADING, USER_LIST_SUCCESS } from "../constants/redux";

export const usersListReducer = (state = {users_List :[]},action) =>{
    switch(action.type){
        case USER_LIST_LOADING:
            return{
                loading : true,
                users_List : [],
                users_length :0
            }
        
        case USER_LIST_SUCCESS:
            return{
                loading : false,
                users_List : action.users_List,
                users_length : action.users_length
            }
            case DELETE_USER_SUCCESS:
                const deletedUserId = action.id;
                // Remove the role with the given ID from the state
                const updatedUsersList = state.users_List.filter(
                  (users) => users.id !== deletedUserId
                );
                console.log(updatedUsersList)
                return {
                  ...state,
                  users_List: updatedUsersList,
                  users_length: state.users_length, // Adjust the length accordingly
                };
          
        default:
            return state
    }
}