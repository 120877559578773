import { Col } from 'antd'
import React from 'react'
import Fare from '../../../../images/fare.png'
import CountUp from 'react-countup';
type Props = {}

const TodayRides = (props: Props) => {
  return (
   <Col lg={12} xs={24} sm={12} xl={6}>
    <div className='dashboardSubPannels py-3 px-4'>
        <div className='flex justify-between w-full items-end mb-3'>
            <p className='font-bold text-xl mb-0'>Today Rides</p>
            <img alt='' src={Fare} className='h-10 w-10'/>
        </div>
        {/* <p className='font-bold text-3xl text-red mb-0'>78</p> */}
        <CountUp start={1} end={78} className='font-bold text-3xl text-red mb-0' />
    </div>
   </Col>
  )
}

export default TodayRides