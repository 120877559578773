import React, { useEffect } from "react";
import logo from "./logo.svg";
import { onAuthStateChanged } from "firebase/auth";
import "./App.scss";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Login from "./modules/Admin/Login/Login";
import Registeration from "./modules/Admin/Registeration/Registeration";
import ForgotPassword from "./modules/Admin/ForgotPassword/ForgotPassword";
import Dashboard from "./modules/Admin/Dashboard/Dashboard";
import { useDispatch } from "react-redux";
import ProtectedRoute from "./config/ProtectedRoute";
import { auth } from "./config/firebase";
import { LOGIN_REDIRECT } from "./constants/redux";
import AdminLayouts from "./Layouts/Admin/AdminLayouts";
import "./scss/Header.scss";
import { Result, Button } from "antd";
import PublicLayout from "./Layouts/Public/PublicLayout";
import PublicSiteLandingPage from "./modules/PublicSite/PublicSiteLandingPage";
import RolesAssign from "./modules/Admin/AdminManagement/Roles/components/RolesAssign";
import RolesPermission from "./modules/Admin/AdminManagement/Roles/RolesPermission/RolesPermission";
import { useIdleTimer } from "react-idle-timer";
import ListUser from "./modules/Admin/AdminManagement/Users/ListUser/ListUser";
import CreateUser from "./modules/Admin/AdminManagement/Users/components/CreateUser";
import VechileMapping from "./modules/Admin/Master/VechileMapping/VechileMapping";
import Driver from "./modules/PublicSite/Driver/Driver";
import Customer from "./modules/Admin/Customer/Customer";
import ManageDrivers from "./modules/Admin/ManageDrivers/ManageDrivers";
import ApprovedDrivers from "./modules/Admin/ManageDrivers/ApprovedDriver";
import RejectedDrivers from "./modules/Admin/ManageDrivers/RejectedDrivers";
import CustomerIndex from "./modules/PublicSite/Customer/CustomerIndex";
import DriverIndex from "./modules/PublicSite/Driver/DriverIndex";

function App() {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); 
  // idle-timer
  // const idleTimer = useIdleTimer({
  //   onIdle() {
  //     localStorage.clear();
  //     navigate("/admin/");
  //   },
  //   timeout: 1000 * 60 * 15,
  // });
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicLayout>
            <PublicSiteLandingPage />
          </PublicLayout>
        }
      />
      <Route
        path="/become-a-rider"
        element={
          <PublicLayout>
            <Driver />
          </PublicLayout>
        }
      />
      <Route
        path="/driver"
        element={
          <PublicLayout>
            <DriverIndex />
          </PublicLayout>
        }
      />
      <Route
        path="/customer"
        element={
          <PublicLayout>
            <CustomerIndex />
          </PublicLayout>
        }
      />
      <Route path="/admin/" element={<Login />} />
      <Route path="/admin/sign-up" element={<Registeration />} />
      <Route path="/admin/reset" element={<ForgotPassword />} />
      <Route
        path="/admin/dashboard"
        element={
          <AdminLayouts>
            <ProtectedRoute element={<Dashboard />} />
          </AdminLayouts>
        }
      />
      <Route
        path="*"
        element={
          <PublicLayout>
            <h1>In-progress</h1>
          </PublicLayout>
        }
      />
      <Route path="/admin/">
        <Route
          path="roles"
          element={
            <AdminLayouts>
              <ProtectedRoute element={<RolesPermission />} />
            </AdminLayouts>
          }
        />
        <Route
          path="roles/assign"
          element={
            <AdminLayouts>
              <ProtectedRoute element={<RolesAssign />} />
            </AdminLayouts>
          }
        />
        <Route
          path="customer"
          element={
            <AdminLayouts>
              <ProtectedRoute element={<Customer />} />
            </AdminLayouts>
          }
        />
        <Route
          path="manage-drivers/pending"
          element={
            <AdminLayouts>
              <ProtectedRoute element={<ManageDrivers />} />
            </AdminLayouts>
          }
        />
        <Route
          path="manage-drivers/rejected"
          element={
            <AdminLayouts>
              <ProtectedRoute element={<RejectedDrivers />} />
            </AdminLayouts>
          }
        />
        <Route
          path="manage-drivers/approved"
          element={
            <AdminLayouts>
              <ProtectedRoute element={<ApprovedDrivers />} />
            </AdminLayouts>
          }
        />
        <Route
          path="roles/edit/:id"
          element={
            <AdminLayouts>
              <ProtectedRoute element={<RolesAssign />} />
            </AdminLayouts>
          }
        />
      </Route>

      <Route path="/admin/">
        <Route
          path="users"
          element={
            <AdminLayouts>
              <ProtectedRoute element={<ListUser />} />
            </AdminLayouts>
          }
        />
        <Route
          path="users/create"
          element={
            <AdminLayouts>
              <ProtectedRoute element={<CreateUser />} />
            </AdminLayouts>
          }
        />
        <Route
          path="users/edit/:id"
          element={
            <AdminLayouts>
              <ProtectedRoute element={<CreateUser />} />
            </AdminLayouts>
          }
        />
        <Route
          path="vehicles-mapping/"
          element={
            <AdminLayouts>
              <ProtectedRoute element={<VechileMapping />} />
            </AdminLayouts>
          }
        />
      </Route>

      <Route
        path="/admin/*"
        element={
          <AdminLayouts>
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
              extra={<Button className="bg-red text-white">Back Home</Button>}
            />
          </AdminLayouts>
        }
      />
    </Routes>
  );
}

export default App;
