import { Button, Col, Row } from "antd";
import React from "react";
import image from "../../../images/publicWebsites/Landing/Section5.png";
type Props = {};

const Section5 = (props: Props) => {
  return (
    <section className="bg-[#F4F4FA]">
      <div className="container mx-auto py-5">
        <Row wrap gutter={[32, 24]}>
          <Col sm={24} xs={24}  xl={10}>
            <div className="h-full">
              <img src={image} alt="" className="h-full max-w-full w-full mx-auto" />
            </div>
          </Col>
          <Col sm={24} xs={24} xl={14}>
            <p className="text-xl font-bold text-black font-Roboto">
              SAFETY FIRST
            </p>
            <h1 className="text-5xl font-bold text-black font-Roboto">
              Your safety comes first. Always.
            </h1>
            <p className="text-xl font-normal font-Roboto text-black py-3">
              We look out for you before, during, and after every single ride.
              And we monitor rides to help you get where you need to go. If you
              ever need it, we’re standing by, ready to help.
            </p>
            <Row wrap gutter={[16,24]}>
                <Col lg={10}><Button className="bg-FA0001 ant-custom-btn text-white rounded-xl h-fit font-Roboto font-extrabold text-3xl md:h-[74px] px-3 xl:px-4">Learn about safety</Button></Col>
                <Col lg={10}>
                    <Button className="bg-transparent community_guidelines text-black border-none shadow-none rounded-xl h-fit font-Roboto font-extrabold text-[25px] md:h-[74px]">Community guidelines <i className="text-2xl ms-2 fa-solid fa-arrow-right"></i></Button>
                </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Section5;
