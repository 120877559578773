import classNames from "classnames";
import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col, Dropdown, Layout } from "antd";
import FB from "../../images/icons/facebook .png";
import insta from "../../images/icons/instagram.png";
import twitter from "../../images/icons/twitter.png";
import linkdin from "../../images/icons/linkedin.png";
import youtube from "../../images/icons/youtube.png";
import playStore from "../../images/publicWebsites/playStore.png";
import appStore from "../../images/publicWebsites/AppStore.png";
import Logo from "../../images/LOGO/LOGO.png";
import ReactFlagsSelect from "react-flags-select";
import Globe from "../../images/icons/world-wide-web 1.png";
import ArrowIcon from "../../images/publicWebsites/AngleIcon.svg";

export const PublicFooter: FC<{}> = () => {
  const [selected, setSelected] = useState("");
  const onSelect = (code: string): void => setSelected(code);

  const items: any = [
    {
      label: "1st menu item",
      key: "1",
    },
  ];

  const { Footer } = Layout;
  return (
    <Footer className="pt-4 bg-white customlayouts">
      <Row>
        <Col lg={8} md={24}>
          <div className="logo">
            <img src={Logo} alt="transit now" />
          </div>
        </Col>
        <Col md={24} lg={{ span: 8, offset: 8 }}>
          <Row gutter={[40, 16]} className="mt-3 md:mt-0">
            <Col md={12} sm={24}>
              <ReactFlagsSelect
                className="md:w-52 h-[51px] !text-xl !text-black !font-medium flags"
                selectedSize={20}
                selected={selected}
                onSelect={onSelect}
              />
            </Col>
            <Col md={12} sm={24}>
              <>
                <Dropdown className="" menu={items}>
                  <>
                    <Button className="flex justify-between font-medium text-xl md:w-52 h-[51px] items-center !border !border-[#807E7E] rounded-lg">
                      <img src={Globe} alt="" /> English{" "}
                      <img src={ArrowIcon} alt="" />
                    </Button>
                  </>
                </Dropdown>
              </>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[40, 16]} className="lg:mt-10 mt-5">
        <Col md={24} lg={6}>
          <p className="font-medium text-xl">ABOUT TRANSIT NOW</p>
          <div className="mt-2 lg:mt-6 flex flex-col">
            <Link
              to="/footer"
              className="font-light text-lg mb-2 capitalize text-black"
            >
              Who We Are
            </Link>
            <Link
              to="/footer"
              className="font-light text-lg mb-2 capitalize text-black"
            >
              Blog
            </Link>
            <Link
              to="/footer"
              className="font-light text-lg mb-2 capitalize text-black"
            >
              Work With Us
            </Link>
            <Link
              to="/footer"
              className="font-light text-lg mb-2 capitalize text-black"
            >
              {" "}
              Investor Relations
            </Link>
            <Link
              to="/footer"
              className="font-light text-lg mb-2 capitalize text-black"
            >
              Report Fraud
            </Link>
            <Link
              to="/footer"
              className="font-light text-lg mb-2 capitalize text-black"
            >
              Press Kit
            </Link>
            <Link
              to="/footer"
              className="font-light text-lg mb-2 capitalize text-black"
            >
              Contact Us
            </Link>
          </div>
        </Col>
        <Col md={24} lg={6}>
          <p className="font-medium text-xl">FOR RESTAURANTS</p>
          <div className="mt-2 lg:mt-6 flex flex-col">
            <Link
              to="/footer"
              className="font-light text-lg mb-2 capitalize text-black"
            >
              Partner With Us
            </Link>
            <Link
              to="/footer"
              className="font-light text-lg mb-2 capitalize text-black"
            >
              Apps For You
            </Link>
          </div>
          <p className="font-medium text-xl mt-4">FOR ENTERPRISES</p>
          <div className="mt-2 lg:mt-6 flex flex-col">
            <Link
              to="/footer"
              className="font-light text-lg mb-2 capitalize text-black"
            >
              Transit Now For Enterprises
            </Link>
          </div>
        </Col>
        <Col md={24} lg={6}>
          <p className="font-medium text-xl">LEARN MORE</p>
          <div className="mt-2 lg:mt-6 flex flex-col">
            <Link
              to="/footer"
              className="font-light text-lg mb-2 capitalize text-black"
            >
              Privacy
            </Link>
            <Link
              to="/footer"
              className="font-light text-lg mb-2 capitalize  text-black"
            >
              Security
            </Link>
            <Link
              to="/footer"
              className="font-light text-lg mb-2 capitalize text-black"
            >
              Terms & Conditions
            </Link>
            <Link
              to="/footer"
              className="font-light text-lg mb-2 capitalize text-black"
            >
              {" "}
              Sitemap
            </Link>
          </div>
        </Col>
        <Col md={24} lg={6}>
          <p className="font-medium text-xl">SOCIAL LINKS</p>
          <div className="flex justify-between md:w-[80%] mb-5 mt-2 lg:mt-6">
            <img className="" src={FB} alt="" />
            <img className="" src={insta} alt="" />
            <img className="" src={twitter} alt="" />
            <img className="" src={linkdin} alt="" />
            <img className="" src={youtube} alt="" />
          </div>
          <div>
            <img className="mb-3 w-64 h-20" src={playStore} alt="" />
            <img className="w-64 h-20" src={appStore} alt="" />
          </div>
        </Col>
      </Row>
    </Footer>
  );
};
