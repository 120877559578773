import React, { useState } from "react";
import NewBanner from "./components/NewBanner";
import MiniTruck from "./components/MiniTruck";
import WhyBe from "./components/WhyBe";
import Section4 from "../Landing Page/Section4";
import Section8 from "../Landing Page/Section8";
import { Modal } from "antd";
import DriverForm from "./DriverForm";
import { useDispatch, useSelector } from "react-redux";

type Props = {};

const DriverIndex = (props: Props) => {
  const dispatch = useDispatch()
  const {driver} = useSelector((state:any) => state);

  return (
    <>
      <NewBanner/>
      <MiniTruck />
      <WhyBe />
      <Section8/>
      <Section4 />
      <Modal
      title={<h3 className="font-Poppins font-bold">{`Driver Form`}</h3>}
      open={driver.modal}
      footer={null}
      width={900}
      onCancel={() => {
        dispatch({
          type : "MODAL_CLOSE"
        })
      }}>
        <DriverForm/>
      </Modal>
    </>
  );
};

export default DriverIndex;
