// VehicleMappingForm.tsx
import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Select } from "antd";
import { validateCurrency } from "../../../../config/validateCurrency";
import { useDispatch, useSelector } from "react-redux";
import {
  VEchileMappingList,
  VechileList,
} from "../../../../interface/interface";
import {
  addVechilePrice,
  handleServiceChange,
  updateVehiclePricingDetails,
} from "../../../../actions/vechileMapping";
import { LOADING } from "../../../../constants/redux";
import { getParticularPriceDetails } from "../../../../api/vechileMapping";
import { serviceAction } from "../../../../actions/driverForm";
interface VehicleMappingFormProps {
  step: number;
  selectedVehicle: number | string | null;
  form: any; // Replace with the correct type
  setSelectedVehicle: (value: number | string) => void;
  handleFormSubmission: (values: any) => void;
  selectedVehicleID: number;
}
interface VechileState {
  vehicles: {
    vechileList: VechileList[];
    vechile_pricing: VEchileMappingList[];
    vehicleMappingList: { vehicleId: number; vehicleName: string }[];
    selectedVehicleList: { vehicleId: number; vehicleName: string };
    id: number;
  };
}
const VehicleMappingForm: React.FC<VehicleMappingFormProps | any> = ({
  step,
  selectedVehicle,
  form,
  setSelectedVehicle,
  selectedService,
  setSelectedService,
  selectedVehicleID,
  filledPrice,
}) => {
  const { vehicles, service }: VechileState | any = useSelector(
    (state: any) => state
  );
  const dispatch: any = useDispatch();
  const [pricingData, setPricingData] = useState<VEchileMappingList>();
  const [vehicleList, setVehicleList] = useState([]);
  const handleFormSubmission = (values: any) => {
    if (pricingData) {
  
      const updatedData = { ...pricingData, ...values };

      dispatch({
        type: LOADING,
        loading: true,
      });
      dispatch(updateVehiclePricingDetails(updatedData));
    } else {
      const combinedVechileData = {
        ...values,
        ...vehicles?.selectedVehicleList,
        ...vehicles?.service
      };
      // console.log(combinedVechileData)
      dispatch({
        type: LOADING,
        loading: true,
      });
      dispatch(addVechilePrice(combinedVechileData));
    }
  };
  const { id } = vehicles;
  // useEffect(() => {
  //   // Make the API call only if there is an 'id'
  //   if (id) {
  //     (async () => {
  //       try {
  //         const data: any = await getParticularPriceDetails(vehicles?.id);
  //         if (data) {
  //           setPricingData(data);
  //         }
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     })();
  //   }
  // }, [id]);
  useEffect(() => {
    if (filledPrice) {
      form.setFieldsValue(filledPrice);
    }
  }, [filledPrice]);
  useEffect(() => {
    (async () => {
      try {
        const data: any = await getParticularPriceDetails(selectedVehicleID);
        if (data) {
          setPricingData(data);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [selectedVehicleID, form, step]);
  useEffect(() => {
    setVehicleList(vehicles?.vehicleMappingList?.ride);
  }, []);
  useEffect(() => {
    dispatch(handleServiceChange(1));
  }, []);

  return (
    <Form
      size="large"
      name="pricingForm"
      form={form}
      colon={false}
      layout="vertical"
      className="my-4 grid lg:grid-cols-2 gap-4 font-Poppins"
      onFinish={handleFormSubmission}
    >
      {step === 1 ? (
        <>
          <Form.Item
            label="Service"
            name="serviceTypeName"
            className="font-Poppins"
            rules={[
              {
                required: true,
                message: "A Service must be selected.",
              },
            ]}
          >
            <Select
              defaultValue={1}
              onChange={(value) => {
                setSelectedService(value);
                dispatch(handleServiceChange(value));
                if (value === 1) {
                  setVehicleList(vehicles?.vehicleMappingList?.ride);
                } else {
                  setVehicleList(vehicles?.vehicleMappingList?.delivery);
                }
              }}
            >
              <Select.Option value={1} className="font-Poppins">
                Ride
              </Select.Option>
              <Select.Option value={2} className="font-Poppins">
                Instant Delivery
              </Select.Option>
              {/* {service?.map(
              (
                service: any,
                index: number
              ) => (
                <Select.Option key={index} value={service.id} className="font-Poppins">
                  {service.name}
                </Select.Option>
              )
            )} */}
            </Select>
          </Form.Item>
          <Form.Item
            label="Vehicle List"
            name="vehicleName"
            className="font-Poppins"
            rules={[
              {
                required: true,
                message: "A vehicle must be selected.",
              },
            ]}
          >
            <Select onChange={(value) => setSelectedVehicle(value)}>
              {vehicleList?.map(
                (
                  vehicle: { vehicleId: number; vehicleName: string }[] | any,
                  index: number
                ) => (
                  <Select.Option
                    key={index}
                    value={vehicle.vehicleId}
                    className="font-Poppins"
                  >
                    {vehicle.vehicleName}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
        </>
      ) : (
        <>
          <Form.Item
            label="Base Price"
            name="basePrice"
            rules={[
              {
                required: true,
                message: "Please enter Base Price.",
              },
              {
                validator: validateCurrency,
              },
            ]}
          >
            <Input style={{ width: 300 }} placeholder="Enter Base Price" />
          </Form.Item>

          <Form.Item
            label="Per Km Charges"
            name="perKMPrice"
            rules={[
              {
                required: true,
                message: "Please enter km/h Price.",
              },
              {
                validator: validateCurrency,
              },
            ]}
          >
            <Input style={{ width: 300 }} placeholder="Enter Per Km Charges" />
          </Form.Item>

          <Form.Item
            label="Peak Hour Charges"
            name="peakHoursPrice"
            rules={[
              {
                required: true,
                message: "Please enter Peak Hour Charges.",
              },
              {
                validator: validateCurrency,
              },
            ]}
          >
            <Input
              style={{ width: 300 }}
              placeholder="Enter Peak Hour Charges"
            />
          </Form.Item>

          <Form.Item
            label=" Waiting Charges"
            name="waitingCharge"
            className="font-Poppins"
            rules={[
              {
                required: true,
                message: "Please enter Waiting Charges.",
              },
              {
                validator: validateCurrency,
              },
            ]}
          >
            <Input style={{ width: 300 }} placeholder="Enter Waiting Charges" />
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default VehicleMappingForm;
