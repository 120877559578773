import {
  __DriverFormHandling,
  ___driverFormURL,
  __getApprovedDriverList,
  __manageDriversURL,
  __manageFileType,
} from "../constants/api";
import { serviceInterceptor } from "./serviceInterceptor";

export const getAllDrivers = () =>
  serviceInterceptor(__manageDriversURL, "GET");

export const getAllApprovedDriver = () =>
  serviceInterceptor(__getApprovedDriverList);

export const getFileType = (id, filetype) =>
  serviceInterceptor(`${__manageFileType}/${id}/${filetype}`, "GET");

export const driverFormApproval = (id, data) =>
  serviceInterceptor(`${__DriverFormHandling}/${id}`, "PUT", data);
