import { Col, Table } from "antd";
import React from "react";
import type { ColumnsType, TableProps } from 'antd/es/table';
import type { SizeType } from 'antd/es/config-provider/SizeContext';

const columns: ColumnsType<any> = [
    {
      title: 'Ride ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
        title: 'Ride Type',
        dataIndex: 'Type',
        sorter: (a, b) => a.Type - b.Type,
      },
    {
        title: 'Rider',
        dataIndex: 'Rider',
        sorter: (a, b) => a.Rider - b.Rider,
      },
      {
        title: 'Driver',
        dataIndex: 'Driver',
        sorter: (a, b) => a.Driver - b.Driver,
      },
      {
        title: 'Address',
        dataIndex: 'Address',
        sorter: (a, b) => a.Address - b.Address,
      },
  ];
  
  
  const data: any[] = [];
  for (let i = 1; i <= 10; i++) {
    data.push({
      key: i,
      id: 12345,
      Type: 'Rentals',
      Rider: `Karthik`,
      Driver: `John Brown`,
      Address:'Lower Parel, Mumbai,       Maharashtra 400013, India'
    });
  }
type Props = {};

const RecentRides = (props: Props) => {
    const tableProps: TableProps<any>|any = {

        showHeader :true,
        footer: undefined,
        scroll : undefined,
        tableLayout : 'auto',
      };
  return (
    <Col lg={24} xl={14}>
      <div className="dashboardSubPannels table py-3">
        <div className="px-4">
          <p className="text-black font-bold text-xl">Recent Rides</p>
        </div>
        <hr className="text-[#C5C1C1]" />
        <Table
        columns={columns}
        {...tableProps}
        dataSource={ data || []}
      />
      </div>
    </Col>
  );
};

export default RecentRides;
