import { _rolesURL } from "../constants/api";
import { serviceInterceptor } from "./serviceInterceptor";

export const getAllRoles = () => serviceInterceptor(`${_rolesURL}/GetAllRoles`, "GET");

export const deleteRole = (record) =>
serviceInterceptor(`${_rolesURL}/RemoveRole/${record.id}`, "DELETE", record);

export const createRole = (role) => {
  const roleDefaults = {
    Id: 0,
    Created_By: 1,
    Modified_By: 1,
    Is_Deleted: false,
  };

  const roleWithDefaults = {
    ...role,
    ...roleDefaults,
  };
  // console.log(roleWithDefaults)

  return serviceInterceptor(`${_rolesURL}/CreateRole`, "POST", roleWithDefaults);
};

export const getParticularRole = (id) =>
serviceInterceptor(`${_rolesURL}/GetRoleById/${id}`, "GET");

export const updateParticularRole = (id, data) =>
serviceInterceptor(`${_rolesURL}/ModifyRole/${id}`, "PUT", data);
