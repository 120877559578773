import { Button, Col, Row } from "antd";
import React from "react";
import image from "../../../images/publicWebsites/Landing/Section4.png";
import Man from '../../../images/publicWebsites/Landing/man.svg';
import Schedule from '../../../images/publicWebsites/Landing/schedule.svg';
import Badge from '../../../images/publicWebsites/Landing/badge.svg';

type Props = {};

const Section4 = (props: Props) => {
  return (
    <section className="container mx-auto pt-10 pb-20">
      <Row className="px-4" gutter={[40, 24]}>
        <Col xl={16} lg={16} md={24} sm={24} xs={24}>
          <h1 className="text-black font-Roboto font-black text-50 mb-2">
            RIDE WITH{" "}
            <span className="text-FA0001 font-Roboto font-black text-50">
              TRANSIT.
            </span>{" "}
          </h1>
          <p className="text-50 text-black font-Roboto font-medium mb-1">
            Ready, set, go <br className="hidden lg:block" />
            in just a few quick taps
          </p>
          <p className="text-xl font-light mb-5">
            No matter your destination, we’ll get you where you need to go
          </p>
          <div className="px-4 mb-5">
            <p className="font-bold text-22 mb-4 font-Roboto flex items-center"><img src={Man} alt='' className="h-auto max-w-full me-3" />Get a reliable ride in minutes</p>
            <p className="font-bold text-22 mb-4 font-Roboto flex items-center"><img src={Schedule} alt='' className="h-auto max-w-full me-3" />Schedule your ride in advance</p>
            <p className="font-bold text-22 mb-4 font-Roboto flex items-center"><img src={Badge} alt='' className="h-auto max-w-full me-3" />Earn rewards on every ride</p>
          </div>
          <Row wrap gutter={[16,24]}>
                <Col lg={6}><Button className="bg-FA0001 ant-custom-btn text-white rounded-xl h-fit font-Roboto font-extrabold text-3xl md:h-[74px] px-3 xl:px-4">Get a Ride</Button></Col>
                <Col lg={10}>
                    <Button className="bg-transparent community_guidelines  text-black border-none shadow-none rounded-xl h-fit font-Roboto font-extrabold text-base lg:text-[25px] md:h-[74px]">Learn more about riding with Tansit <i className="text-2xl ms-2 fa-solid fa-arrow-right"></i></Button>
                </Col>
            </Row>
        </Col>
        <Col xl={6} offset={2} lg={6} md={24} sm={24} xs={24}>
          <img src={image} alt="" className="h-full max-w-full" />
        </Col>
      </Row>
    </section>
  );
};

export default Section4;
