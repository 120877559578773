import { Button, DatePicker, Form, Input, message, ConfigProvider } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listVechile } from "../../../../../actions/vechileMapping";
import { PersonalInfoProps } from "../../../../../interface/interface";
import {
  onChangeAddress,
  onChangeDOB,
  onChangeEmail,
  onChangeFirstName,
  onChangeLastName,
  onChangeNumber,
  tabs,
} from "../../../../../actions/driverForm";
import locale from "antd/lib/locale/en_US";
import dayjs from "dayjs";
import "dayjs/locale/en";

dayjs.locale("en");

const PersonalInfo: React.FC<PersonalInfoProps> = () => {
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();
  const { driver }: any = useSelector((state) => state);
  const { currentTab, personalInfo } = driver;

  useEffect(() => {
    dispatch(listVechile());
  }, []);

  useEffect(() => {
    // if (
    //   personalInfo &&
    //   Object.values(personalInfo).every((value) => value !== "")
    // ) {
      form.setFieldsValue({
        ...personalInfo,
        dob: personalInfo.dob ? dayjs(personalInfo.dob, { locale: "en" }) : null,
      });
    // }
  }, [currentTab]);
  useEffect(() => {
    // if (
    //   personalInfo &&
    //   Object.values(personalInfo).every((value) => value !== "")
    // ) {
      form.setFieldsValue({
        ...personalInfo,
        dob: personalInfo.dob ? dayjs(personalInfo.dob, { locale: "en" }) : null,
      });
    // }
  }, [personalInfo]);

  return (
    <div className="py-4">
      <Form
        size="large"
        name="driverPersonalInfoForm"
        form={form}
        colon={true}
        // requiredMark="optional"
        layout="vertical"
        className="mt-  lg:grid lg:grid-cols-2 gap-x-12 gap-y-3"
        // labelAlign="left"
        labelCol={{
          span: 12,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={() => {
          dispatch(tabs(1));
        }}
      >
        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
              message: "The First Name is required.",
            },
          ]}
        >
          <Input
            onChange={(e) => {
              const value = e.target.value;
              dispatch(onChangeFirstName(value));
            }}
            className="border-[#D3D2D2] bg-[#F4F4F4] border text-xl font-Roboto font-normal p-6"
            size="large"
            placeholder="First Name"
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
              message: "The Last Name is required.",
            },
          ]}
        >
          <Input
            onChange={(e) => {
              const value = e.target.value;
              dispatch(onChangeLastName(value));
            }}
            className="border-[#D3D2D2] bg-[#F4F4F4] border text-xl font-Roboto font-normal p-6"
            size="large"
            placeholder="Last Name"
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "The Email is required.",
            },
            {
              type: "email",
              message: "Please enter a valid email address.",
            },
          ]}
        >
          <Input
            onChange={(e) => {
              const value = e.target.value;
              dispatch(onChangeEmail(value));
            }}
            className="border-[#D3D2D2] bg-[#F4F4F4] border text-xl font-Roboto font-normal p-6"
            size="large"
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="mobileNo"
          rules={[
            {
              required: true,
              message: "The Mobile Number is required.",
            },
            {
              pattern: /^[0-9]{10}$/,
              message: "Please enter a valid 10-digit phone number.",
            },
          ]}
        >
          <Input
            onChange={(e) => {
              const value = e.target.value;
              dispatch(onChangeNumber(value));
            }}
            className="border-[#D3D2D2] bg-[#F4F4F4] border text-xl font-Roboto font-normal p-6"
            size="large"
            placeholder="Mobile Number"
          />
        </Form.Item>
        <Form.Item
          name="dob"
          rules={[
            {
              required: true,
              message: "The DOB is required.",
            },
          ]}
        >
          <ConfigProvider locale={locale}>
            <DatePicker
            format={'DD-MM-YYYY'}
              className="border-[#D3D2D2] bg-[#F4F4F4] border text-xl font-Roboto font-normal p-6 w-full"
              size="large"
              placeholder="DOB"
              onChange={(date, dateString) => {
                dispatch(onChangeDOB(date ? date.format("YYYY-MM-DD") : ""));
              }}
            />
          </ConfigProvider>
        </Form.Item>
        <Form.Item
          name="address"
          rules={[
            {
              required: true,
              message: "The Address is required.",
            },
          ]}
        >
          <Input
            onChange={(e) => {
              const value = e.target.value;
              dispatch(onChangeAddress(value));
            }}
            className="border-[#D3D2D2] bg-[#F4F4F4] border text-xl font-Roboto font-normal p-6"
            size="large"
            placeholder="Address"
          />
        </Form.Item>
      </Form>
      <div className="w-full flex justify-center">
        <Button
          htmlType="submit"
          onClick={() => form.submit()}
          className="bg-red ant-custom-btn uppercase font-bold !text-2xl text-white !mt-2 !h-16 w-48 !rounded-xl hover:border-red"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default PersonalInfo;
