import { Button, Col, Form, Input, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { Element } from "react-scroll";

type Props = {};

const Section8 = (props: Props) => {
  return (
    <Element name="contact-us-section" className="element">
      <section className="bg-contact-us-pattern h-full bg-repeat-round">
        <div className="container mx-auto py-20 ">
          <Row wrap gutter={[48, 16]}>
            <Col xs={24} sm={24} md={24} lg={12}>
              <h1 className="text-white font-black text-[50px] leading-[58px] font-Roboto">
                Contact{" "}
                <span className="text-FA0001 font-black text-[50px] leading-[58px]">
                  us
                </span>
              </h1>
              <div className="mt-5">
                <p className="text-white font-medium text-2xl md:text-3xl flex font-Roboto mb-4">
                  <Button
                    type="primary"
                    className="bg-white me-4"
                    shape="circle"
                    size="large"
                    icon={
                      <i className="text-black text-xl  md:text-2xl fa-solid fa-phone"></i>
                    }
                  />
                  +12 258 958 6549
                </p>
                <p className="text-white font-medium text-2xl md:text-3xl flex font-Roboto mb-4">
                  <Button
                    type="primary"
                    className="bg-white me-4"
                    shape="circle"
                    size="large"
                    icon={
                      <i className="text-black text-2xl fa-regular fa-envelope"></i>
                    }
                  />
                  contact@transitnow.com
                </p>
                <p className="text-white font-medium text-2xl md:text-3xl flex font-Roboto mb-4 w-[70%]">
                  <Button
                    type="primary"
                    className="bg-transparent shadow-none border-none me-4"
                    size="large"
                    icon={
                      <i className="text-white fa-solid text-2xl fa-location-dot"></i>
                    }
                  />
                  123, Twin Tower, 1st Floor, Malaysia
                </p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form className="xl:w-[512px]">
                <Input
                  placeholder="Name*"
                  className="h-[60px] bg-transparent text-white text-xl mb-3 name px-3"
                />
                <Input
                  placeholder="Email*"
                  className="h-[60px] bg-transparent text-white text-xl mb-3 email px-3"
                />
                <Input className="h-[60px] bg-transparent text-white text-xl mb-3 phonenumber" />
                <Input className="h-[60px] bg-transparent text-white text-xl mb-3 enquiry" />
                <TextArea
                  rows={6}
                  placeholder="Message"
                  maxLength={6}
                  className="mb-3"
                />
                <div className="w-full text-end">
                  <Button className="bg-FA0001 ant-custom-btn text-white font-black text text-3xl h-16 border-FA0001 ">
                    SUBMIT
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </section>
    </Element>
  );
};

export default Section8;
