import { postDriverForm } from "../api/driverFormAPI";
import { getFileType } from "../api/manageDriversAPI";
import { getALLservice } from "../api/vechileMapping";
import { ___driverFormURL, __manageDriversURL } from "../constants/api";
import { message } from "antd";
import {
  DRIVER_ADDRESS,
  DRIVER_DOB,
  DRIVER_EMAIL,
  DRIVER_FIRST_NAME,
  DRIVER_FORM_SUCCESS,
  DRIVER_GOVT_ID,
  DRIVER_LAST_NAME,
  DRIVER_LICENSE_NUMBER,
  DRIVER_LICENSE_PIC,
  DRIVER_PAN_CARD,
  DRIVER_PHONE,
  DRIVER_PROFILE_PHOTO,
  DRIVER_RC_PIC,
  DRIVER_TAB,
  DRIVER_VECHILE_TYPE,
  DRIVER_VEHICLE_INSURANCE_PIC,
  DRIVER_VEHICLE_MODEL,
  DRIVER_VEHICLE_NUMBER,
  DRIVER_VEHICLE_PERMIT,
  DRIVER_VEHICLE_PERMIT_PIC,
  DRIVER_VEHICLE_PIC,
} from "../constants/redux";

export const tabs = (tabs) => async (dispatch) => {
  dispatch({
    type: DRIVER_TAB,
    tab: tabs,
  });
};

export const onChangeFirstName = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_FIRST_NAME,
    value: value,
  });
};
export const onChangeLastName = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_LAST_NAME,
    value: value,
  });
};
export const onChangeEmail = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_EMAIL,
    value: value,
  });
};
export const onChangeNumber = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_PHONE,
    value: value,
  });
};

export const onChangeDOB = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_DOB,
    value: value,
  });
};

export const onChangeAddress = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_ADDRESS,
    value: value,
  });
};

export const onChangeVehicle = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_VECHILE_TYPE,
    value: value,
  });
};

export const onChangeVehicleNumber = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_VEHICLE_NUMBER,
    value: value,
  });
};

export const onChangeVehicleLicense = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_LICENSE_NUMBER,
    value: value,
  });
};

export const onChangeVehiclePermit = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_VEHICLE_PERMIT,
    value: value,
  });
};

export const onChangeProfilePic = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_PROFILE_PHOTO,
    value: value,
  });
};

export const onChangeVehiclePic = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_VEHICLE_PIC,
    value: value,
  });
};

export const onChangeGovtID = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_GOVT_ID,
    value: value,
  });
};

export const onChangeLicense = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_LICENSE_PIC,
    value: value,
  });
};

export const onChangePanCard = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_PAN_CARD,
    value: value,
  });
};

export const onChangeRC = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_RC_PIC,
    value: value,
  });
};

export const onChangePermit = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_VEHICLE_PERMIT_PIC,
    value: value,
  });
};

export const onChangeInsurance = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_VEHICLE_INSURANCE_PIC,
    value: value,
  });
};

export const onChangeVehicleModel = (value) => async (dispatch) => {
  dispatch({
    type: DRIVER_VEHICLE_MODEL,
    value: value,
  });
};

export const driverSubmit = () => async (dispatch, getState) => {
  try {
    const { driver } = getState();
    const { personalInfo, vehicleInfo, uploadDocuments } = driver;
    const obj = {
      name: `${personalInfo.firstName} ${personalInfo.lastName}`,
      mobileNo: personalInfo.mobileNo,
      address: personalInfo.address,
      email: personalInfo.email,
      photo: uploadDocuments?.profilePic,
      govtIDProof: uploadDocuments.govtID,
      dob: personalInfo.dob,
      status: "pending",
    };

    const driverForm = new FormData();
    for (const key in obj) {
      driverForm.append(key, obj[key]);
    }

    var driverVehicle = [];
    driverVehicle.push({
      RCBookImage: uploadDocuments.rc,
      InsuranceImage: uploadDocuments.vehicleInsurance,
      DrivingLicenceImage: uploadDocuments.driverLicense,
      VehiclePhoto: uploadDocuments.vehiclePic,
      VehicleType: vehicleInfo.vehicleType,
      VehicleModel: vehicleInfo.vehicleModel,
      RegNo: vehicleInfo.vehicleNumber,
      DLNumber : vehicleInfo?.vehicleLicense
    });

    for (var i = 0; i < driverVehicle.length; i++) {
      driverForm.append(
        "driverVehicle[" + i + "].VehicleType",
        driverVehicle[i].VehicleType
      );
      driverForm.append(
        "driverVehicle[" + i + "].VehicleModel",
        driverVehicle[i].VehicleModel
      );
      driverForm.append(
        "driverVehicle[" + i + "].RegNo",
        driverVehicle[i].RegNo
      );
      driverForm.append(
        "driverVehicle[" + i + "].RCBookImage",
        driverVehicle[i].RCBookImage
      );
      driverForm.append(
        "driverVehicle[" + i + "].InsuranceImage",
        driverVehicle[i].InsuranceImage
      );
      driverForm.append(
        "driverVehicle[" + i + "].DLNumber",
        driverVehicle[i].DLNumber
      );
      driverForm.append(
        "driverVehicle[" + i + "].DrivingLicenceImage",
        driverVehicle[i].DrivingLicenceImage
      );
      driverForm.append(
        "driverVehicle[" + i + "].VehiclePhoto",
        driverVehicle[i].VehiclePhoto
      );
      driverForm.append(
        "driverVehicle[" + i + "].fileDetails5",
        driverVehicle[i].fileDetails5
      );
      driverForm.append(
        "driverVehicle[" + i + "].fileDetails6",
        driverVehicle[i].fileDetails6
      );
    }

    const headers = new Headers();
    headers.append("Content-Type", "multipart/form-data");
    const requestOptions = {
      method: "POST",
      // headers: {
      //   'Content-Type': 'multipart/form-data'
      // },
      body: driverForm,
    };
    const response = await fetch(___driverFormURL, requestOptions);
    if (response.StatusCode !== 500) {
      dispatch({
        type: DRIVER_FORM_SUCCESS,
        value: true,
      });
      dispatch({
        type : 'MODAL_CLOSE'
      })
      
    } else {
      message.error("Error submitting driver form");
    }
  } catch (error) {
    console.log(error);
    // Display error message
    message.error("An error occurred while submitting the driver form");
  }
};

export const handleFileType = (id, fileType) => async (disptach, getState) => {
  const response = await getFileType(id, fileType);
  console.log(response)
};

export const serviceAction = () => async (dispatch, getstate) => {
  const response = await getALLservice();
  dispatch({
    type: "SERVICE",
    data: response,
  });
};

export const handleFileDownloadResponse = async (response, fileName) => {
  if (response.ok) {
    try {
      const fileExtension =
        response.headers
          ?.get("content-disposition")
          ?.split(";")
          .find((n) => n.includes("filename="))
          ?.replace("filename=", "")
          .trim().split('.')[1].toLowerCase() ?? "jpeg";

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.${fileExtension}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error("Error processing file:", error);
    }

  } else {
    console.error("Failed to fetch data:", response.status);
  }
};