import { Button, Tag, Popconfirm, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import "../roles.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listRoles } from "../../../../../actions/roles";
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { deleteRole } from "../../../../../api/rolesAPI";
import { toast } from "react-toastify";
import { DELETE_ROLE_SUCCESS } from "../../../../../constants/redux";
import moment from "moment";
import Edit from '../../../../../images/Driver/edit.svg'
import Delete from '../../../../../images/Driver/delete.svg'

const RolesPermission = (props) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { roles } = useSelector((state) => state);
  const { roles_permission, roles_length } = roles;
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      position: "bottomRight",
    },
  });

  useEffect(() => {
    setLoading(true);
    dispatch(listRoles());
  }, [dispatch]);

  useEffect(() => {
    setTableData(roles_permission);
    setLoading(false);
  }, [roles_permission]);

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: roles_length,
      },
    });
  }, [roles_length]);

  const columns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (tags) => (
        <span>
          <Tag color={tags ? 'green' : 'red'}>{tags ? 'Active' : 'In-active'}</Tag>
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "created_Date",
      render: (createdDate) => moment(createdDate).format("MMMM Do YYYY"),
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => (
        <div className="flex">
          {renderEditIcon(record)}
          {renderDeleteIcon(record)}
        </div>
      ),
    },
  ];

  const renderEditIcon = (record) => (
    <Tooltip title="Edit" color={"green"}>
      <img
        alt='edit'
        src={Edit}
        className="cursor-pointer me-4"
        onClick={() => navigation(`/admin/roles/edit/${record.id}`)}
      />
    </Tooltip>
  );

  const renderDeleteIcon = (record) => (
    <Popconfirm
      title="Delete the role"
      description="Are you sure to delete this role?"
      className="roles-pop"
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      onConfirm={async () => handleDeleteRole(record)}
      okText="Delete"
      okButtonProps={{
        className: "custom-ok-button bg-red text-white hover:!bg-red",
      }}
    >
      <img
        alt='delete'
        src={Delete}
        className="cursor-pointer"
        // onClick={() => navigation(`/admin/roles/edit/${record.id}`)}
      /> 
    </Popconfirm>
  );

  const handleDeleteRole = async (record) => {
    const data = await deleteRole(record);
    if (data) {
      toast.success("Deleted Successfully !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      dispatch({
        type: DELETE_ROLE_SUCCESS,
        roleID: record.id,
      });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      ...tableParams,
      pagination,
      filters,
      sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setTableData([]); // Reset data if pageSize changed
    }
  };

  return (
    <section className="py-10 px-2 roles-permission">
      <div className="flex justify-between">
        <p className="text-2xl font-medium">Roles & Permission</p>
        <Button
          type="primary"
          onClick={() => navigation("/admin/roles/assign")}
          className="roles-button"
          size="large"
          icon={<i className="fa-solid fa-plus"></i>}
        >
          Assign Roles
        </Button>
      </div>
      <div className="py-3">
        <Table
          size="large"
          className="transit-custom-table"
          columns={columns}
          dataSource={tableData}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
    </section>
  );
};

export default RolesPermission;
