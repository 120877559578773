import React from "react";
import profile from "../../images/profile-user.png";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem, Divider } from "rc-menu";
import "rc-dropdown/assets/index.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_REDIRECT } from "../../constants/redux";
import { Modal, Form, Input, Button } from "antd";
import { decodeJwtToken } from "../../config/decodeAuth";
import { jwt } from "../../constants/jwtConstant";
import { changePassword } from "../../api/changePassword";
import { toast } from "react-toastify";
const UserProfileDropDown = (props) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const [userDetails, setUserDetails] = React.useState({
    name: null,
    id: null,
    email: null,
  });

  React.useEffect(() => {
 
    if (user) {
      
      const decodedUserDetails = decodeJwtToken(user?.token);
      setUserDetails({
        name: decodedUserDetails[`${jwt}/givenname`] || null,
        id: decodedUserDetails[`${jwt}/nameidentifier`] || null,
        email: decodedUserDetails[`${jwt}/emailaddress`] || null,
      });
    } else {
      console.error('User details are not available');
    }
  }, []);
 
  // useRef for onVisibleChange to ensure stability across renders
  const onVisibleChangeRef = React.useRef();
  const [form] = Form.useForm();
  React.useEffect(() => {
    onVisibleChangeRef.current = onVisibleChange;
  }, []);

  function onSelect({ key }) {
    console.log(`${key} selected`);
  }

  function onVisibleChange(visible) {
    console.log(visible);
  }
  const [confirmLoading, setIsConfirmLoading] = React.useState(false);
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    setIsConfirmLoading(true);
    const {id} = userDetails
    const response = await changePassword(id, { ...values, id });
    if (response) {
      setIsConfirmLoading(false)
      toast.success("Password Updated Successfully !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      dispatch({
        type: "MODAL",
        data: false,
      });
    }
  };
  const { modal } = useSelector((state) => state);

  const menu = (
    <>
      <Menu onSelect={onSelect} className="!rounded-lg py-2">
        <Divider />
        <MenuItem
          key="2"
          onClick={() => {
            dispatch({
              type: "MODAL",
              data: true,
            });
          }}
          className="mt-2 px-4"
        >
          <p className="text-base font-semibold mb-2 cursor-pointer font-Poppins">
            Change Password
          </p>
        </MenuItem>
        <MenuItem
          key="2"
          onClick={() => {
            dispatch({
              type: LOGIN_REDIRECT,
              redirect: false,
              payload: {},
            });
            // navigate("/admin/");
            localStorage.clear();
          }}
          className="py-2 px-4"
        >
          <p className="text-base font-semibold mb-2 cursor-pointer font-Poppins">
            Logout
          </p>
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <div>
      <Dropdown
        trigger={["click"]}
        overlay={menu}
        animation="slide-up"
        onVisibleChange={(visible) => {
          onVisibleChangeRef.current(visible);
        }}
      >
        <p className="flex font-medium text-xl items-center">
          <img src={profile} alt="" className="w-5 h-5 me-2" />{" "}
          {userDetails?.name}
        </p>
      </Dropdown>
      <Modal
        title={<h3 className="font-Poppins font-bold">{`Change Password`}</h3>}
        open={modal}
        footer={
          <>
            <Button
              size="large"
              loading={confirmLoading}
              className="bg-green-500  !h-11 text-white border-green-500"
              onClick={() => form.submit()}
            >
              Update Password
            </Button>
          </>
        }
        width={900}
        onCancel={() => {
          dispatch({
            type: "MODAL",
            data: false,
          });
        }}
      >
        <Form
          form={form}
          name="changePasswordForm"
          size="large"
          colon={false}
          layout="inline"
          className="my-4 grid lg:grid-cols-2 gap-4 font-Poppins"
          onFinish={onFinish}
        >
          {/* New Password */}
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please enter your new password!",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters long.",
              },
              {
                pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
                message:
                  "Password must contain at least one number and one special character.",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          {/* Confirm Password */}
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          {/* Submit Button */}
          {/* <Form.Item>
            <Button type="primary" htmlType="submit">
              Change Password
            </Button>
          </Form.Item> */}
        </Form>
      </Modal>
    </div>
  );
};

export default UserProfileDropDown;
