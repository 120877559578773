import { Button, Tag, Popconfirm, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import "../users.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {  listUsers } from "../../../../../actions/users";
import { _usersURL } from "../../../../../constants/api";
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { deleteUser } from "../../../../../api/usersAPI";
import { toast } from "react-toastify";
import { DELETE_USER_SUCCESS } from "../../../../../constants/redux";
import moment from "moment";
import Edit from '../../../../../images/Driver/edit.svg'
import Delete from '../../../../../images/Driver/delete.svg'

type Props = {};

const ListUser = (props: Props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const { users } = useSelector((state) => state);
    const { users_List, users_length } = users;
    const [tableParams, setTableParams] = useState({
      pagination: {
        current: 1,
        pageSize: 10,
        position: "bottomRight",
      },
    });
  

    useEffect(() => {
        setLoading(true)
        dispatch(listUsers());
      }, [dispatch]);
      useEffect(() => {
        setData(users_List);
        setLoading(false)
      }, [users_List]);
      
      useEffect(() => {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: users_length,
          },
        });
      }, [users_length]);
      
      const columns = [
        {
            title: "UserName",
            dataIndex: "userId",
            sorter: true,
          },
          // {
          //   title: "Password",
          //   dataIndex: "password",
          // },
          {
            title: "First Name",
            dataIndex: "firstName",
          },
          {
            title: "Email Id",
            dataIndex: "email_Id",
        },
        {
          title: "Role",
          dataIndex: "role_Name",
      },
        {
            title: "Mobile Number",
            dataIndex: "mobile_Number",
        },
        {
            title: "Created Date",
            dataIndex: "created_Date",
            render: (createdDate) => moment(createdDate).format("DD-MMMM-YYYY"),
          },
        {
          title: "Status",
          dataIndex: "status",
          render: (tags) => (
            <span>
              <Tag color={tags ? 'green' : 'red'}>{tags ? 'Active' : 'In-Active'}</Tag>
            </span>
          ),
        },       
        {
          title: "Action",
          dataIndex: "operation",
          render: (_, record) => (
            <div className="flex">
              <Tooltip title="Edit" color={"green"}>
                {/* <EditOutlined className="text-green-500 text-lg me-4"  onClick={() => navigate(`/admin/users/edit/${record.id}`)}/> */}
                <img
                  alt='edit'
                  src={Edit}
                  className="cursor-pointer me-4"
                  onClick={() => navigate(`/admin/users/edit/${record.id}`)}
                />
              </Tooltip>
    
              <Popconfirm
                title="Delete the user"
                description="Are you sure to delete this user?"
                className="users-pop"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={async () => {
                  const data = await deleteUser(record);
                  if (data) {
                    toast.success("Deleted Successfully !", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    dispatch({
                      type: DELETE_USER_SUCCESS,
                      userID: record.id,
                    });
                  }
                }}
                okText="Delete"
                okButtonProps={{
                  className: "custom-ok-button bg-red text-white hover:!bg-red",
                }}
              >
                <img
                  alt='delete'
                  src={Delete}
                  className="cursor-pointer"
                  // onClick={() => navigation(`/admin/roles/edit/${record.id}`)}
                /> 
              </Popconfirm>
            </div>
          ),
        },
      ];
      const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
          ...tableParams,
          pagination,
          filters,
          sorter,
        });
    
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
          setData([]);
        }
      };
    
  return (
    <section className="py-10 px-2 create-users">
    <div className="flex justify-between">
      <p className="text-2xl font-medium">Users List</p>
      <Button
        type="primary"
        onClick={() => navigate("/admin/users/create")}
        className="users-button"
        size="large"
        icon={<i className="fa-solid fa-plus"></i>}
      >
        Create User
      </Button>
    </div>
    <div className="py-3">
      <Table
        size="large"
        columns={columns}
        className="transit-custom-table"
        // className="table"
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  </section>
  );
};

export default ListUser;
