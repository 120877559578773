import { Button, Popconfirm, Table, Tooltip, Divider, Modal, Form } from "antd";
import VechileMappingForm from "./VehicleMappingForm";
import { useEffect, useState } from "react";
import { getParticularPriceDetails } from "../../../../api/vechileMapping";
import {useDispatch} from 'react-redux'
import { serviceAction } from "../../../../actions/driverForm";
import { vechileMappingList } from "../../../../actions/vechileMapping";
const PriceModalContent = ({
  step,
  buttonText,
  vehicles,
  form,
  setSelectedVehicle,
  selectedVehicle,
  selectedService,
  setSelectedService,
  handleGoBack,
  filledPrice
}) => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (step !== 1) {
  //     (async () => {
  //       try {
  //         const response = await getParticularPriceDetails(
  //           vehicles?.selectedVehicleList?.vehicleId
  //         );
  //         setData(response);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     })();
  //   }
  // }, [vehicles?.selectedVehicleList?.vehicleId]);
  useEffect(() =>{
    dispatch(serviceAction())
    dispatch(vechileMappingList())
  },[])
  return (
    <>
      {step !== 1 && (
        <h5 className="mt-4 font-Poppins">
          {buttonText === "Submit" && (
            <Tooltip title="Go Back to vehicle Select">
              <Button
                onClick={handleGoBack}
                className="roles-button bg-transparent text-slate-950 border-0"
                size="large"
                icon={<i className="fa-solid fa-arrow-left"></i>}
              ></Button>
            </Tooltip>
          )}
          Vehicle Name : {vehicles?.selectedVehicleList?.vehicleName}, Service :{" "}
          {selectedService}
        </h5>
      )}
      <VechileMappingForm
        step={step}
        selectedVehicle={selectedVehicle}
        form={form}
        setSelectedVehicle={setSelectedVehicle}
        setSelectedService={setSelectedService}
        selectedService={selectedService}
        filledPrice={filledPrice}
        selectedVehicleID={vehicles?.selectedVehicleList?.vehicleId}
        // handleFormSubmission={handleFormSubmission}
      />
    </>
  );
};
export default PriceModalContent;
