// Import the functions you need from the SDKs you need
import  {getAuth} from 'firebase/auth'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBVgI_knY8WGeUZ6wEduyY2DRWSpSjTQHY",
  authDomain: "transitnow-8e9f8.firebaseapp.com",
  projectId: "transitnow-8e9f8",
  storageBucket: "transitnow-8e9f8.appspot.com",
  messagingSenderId: "706169910002",
  appId: "1:706169910002:web:22211639f3e8a9c985166b",
  measurementId: "G-NRBPJVCK5Z"
} 

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app)