import React from "react";
import TabMenu from "./TabMenu";
import PersonalInfo from "./PersonalInfo";
import { useSelector } from "react-redux";
import VehicleInfo from "./VehicleInfo";
import UploadDocuments from "./UploadDocuments";

type Props = {};

const Tabs: React.FC<Props> = (props) => {
  const { driver }: any = useSelector(
    (state) => state
  );
  const { currentTab } = driver;

  return (
    <div>
      <TabMenu />
      <div className="py-8">
        {currentTab === 0 && <PersonalInfo/>}
        {currentTab === 1 && <VehicleInfo/>}
        {currentTab === 2 && <UploadDocuments/>}
      </div>
    </div>
  );
};

export default Tabs;
