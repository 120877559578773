import React from "react";
import { useSelector } from "react-redux";

type Props = {};

const TabMenu = (props: Props) => {
  const { driver }: any = useSelector((state) => state);
  const { currentTab } = driver;
  return (
    <div className="border-b flex border-black w-full mt-9">
      {["Personal Info", "Vehicle Info", "Upload Documents"].map(
        (menu, index) => (
          <div
            key={index}
            className={`${
              currentTab === index ? "bg-black" : "bg-[#8C8C8C]"
            } w-40 lg:w-60 h-14 flex justify-center items-center rounded-t-[1.5rem]`}
          >
            <span className="font-Robot text-white font-bold lg:text-xl">
              {menu}
            </span>
          </div>
        )
      )}
    </div>
  );
};

export default TabMenu;
