import React from 'react'
import Section1 from './Landing Page/Section1'
import './Landing Page/Landing.scss';
import Section2 from './Landing Page/Section2';
import Section3 from './Landing Page/Section3';
import Section5 from './Landing Page/Section5';
import Section6 from './Landing Page/Section6';
import Section4 from './Landing Page/Section4';
import Section8 from './Landing Page/Section8';
import Section7 from './Landing Page/Section7';

type Props = {}

const PublicSiteLandingPage = (props: Props) => {
  return (
    <>
        <Section1/>
        <Section2/>
        <Section3/>
        <Section4/>
        <Section5/>
        <Section6/>
        <Section7/>
        <Section8/>
    </>
  )
}

export default PublicSiteLandingPage