import {
  DRIVER_ADDRESS,
  DRIVER_DOB,
  DRIVER_EMAIL,
  DRIVER_FIRST_NAME,
  DRIVER_GOVT_ID,
  DRIVER_LAST_NAME,
  DRIVER_LICENSE_NUMBER,
  DRIVER_LICENSE_PIC,
  DRIVER_PAN_CARD,
  DRIVER_PHONE,
  DRIVER_PROFILE_PHOTO,
  DRIVER_RC_PIC,
  DRIVER_TAB,
  DRIVER_VECHILE_TYPE,
  DRIVER_VEHICLE_INSURANCE_PIC,
  DRIVER_VEHICLE_NUMBER,
  DRIVER_VEHICLE_PERMIT,
  DRIVER_VEHICLE_PERMIT_PIC,
  DRIVER_VEHICLE_PIC,
  DRIVER_VEHICLE_MODEL,
  DRIVER_FORM_SUCCESS,
} from "../constants/redux";

const initialState = {
  currentTab: 0,
  redirect: false,
  modal:false,
  personalInfo: {
    firstName: "",
    lastName: "",
    mobileNo: "",
    dob: "",
    address: "",
    email: "",
  },
  vehicleInfo: {
    vehicleType: "",
    vehicleNumber: "",
    vehiclePermit: "",
    vehicleLicense: "",
    vehicleModel: "",
  },
  uploadDocuments: {
    profilePic: [],
    vehiclePic: [],
    govtID: [],
    driverLicense: [],
    panCard: [],
    rc: [],
    vehiclePermit: [],
    vehicleInsurance: [],
  },
};

export const driverReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DRIVER_TAB:
      return { ...state, currentTab: action.tab };
    case DRIVER_FIRST_NAME:
      return {
        ...state,
        personalInfo: { ...state.personalInfo, firstName: action.value },
      };
    case DRIVER_LAST_NAME:
      return {
        ...state,
        personalInfo: { ...state.personalInfo, lastName: action.value },
      };
    case DRIVER_EMAIL:
      return {
        ...state,
        personalInfo: { ...state.personalInfo, email: action.value },
      };
    case DRIVER_PHONE:
      return {
        ...state,
        personalInfo: { ...state.personalInfo, mobileNo: action.value },
      };
    case DRIVER_DOB:
      return {
        ...state,
        personalInfo: { ...state.personalInfo, dob: action.value },
      };
    case DRIVER_ADDRESS:
      return {
        ...state,
        personalInfo: { ...state.personalInfo, address: action.value },
      };
    case DRIVER_VEHICLE_NUMBER:
      return {
        ...state,
        vehicleInfo: { ...state.vehicleInfo, vehicleNumber: action.value },
      };
    case DRIVER_VECHILE_TYPE:
      return {
        ...state,
        vehicleInfo: { ...state.vehicleInfo, vehicleType: action.value },
      };
    case DRIVER_LICENSE_NUMBER:
      return {
        ...state,
        vehicleInfo: { ...state.vehicleInfo, vehicleLicense: action.value },
      };
    case DRIVER_VEHICLE_PERMIT:
      return {
        ...state,
        vehicleInfo: { ...state.vehicleInfo, vehiclePermit: action.value },
      };
    case DRIVER_VEHICLE_MODEL:
      return {
        ...state,
        vehicleInfo: { ...state.vehicleInfo, vehicleModel: action.value },
      };
    case DRIVER_PROFILE_PHOTO:
      return {
        ...state,
        uploadDocuments: { ...state.uploadDocuments, profilePic: action.value },
      };
    case DRIVER_VEHICLE_PIC:
      return {
        ...state,
        uploadDocuments: { ...state.uploadDocuments, vehiclePic: action.value },
      };
    case DRIVER_GOVT_ID:
      return {
        ...state,
        uploadDocuments: { ...state.uploadDocuments, govtID: action.value },
      };
    case DRIVER_LICENSE_PIC:
      return {
        ...state,
        uploadDocuments: {
          ...state.uploadDocuments,
          driverLicense: action.value,
        },
      };
    case DRIVER_PAN_CARD:
      return {
        ...state,
        uploadDocuments: { ...state.uploadDocuments, panCard: action.value },
      };
    case DRIVER_RC_PIC:
      return {
        ...state,
        uploadDocuments: { ...state.uploadDocuments, rc: action.value },
      };
    case DRIVER_VEHICLE_PERMIT_PIC:
      return {
        ...state,
        uploadDocuments: {
          ...state.uploadDocuments,
          vehiclePermit: action.value,
        },
      };
    case DRIVER_VEHICLE_INSURANCE_PIC:
      return {
        ...state,
        uploadDocuments: {
          ...state.uploadDocuments,
          vehicleInsurance: action.value,
        },
      };
    case DRIVER_FORM_SUCCESS:
      return{...state, redirect :true}
    case "RESET":
      return initialState;
    case "MODAL_OPEN":
      return {...state,modal:true}
      case "MODAL_CLOSE":
        return {...state,modal:false}
    default:
      return state;
  }
};
