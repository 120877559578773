import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export default function ProtectedRoute({ element }) {
  const isAuthenticated = useSelector((state) => state.login.redirect);
  const userLocalStorage = localStorage.getItem('user');
  // console.log({isAuthenticated,userLocalStorage})
  if (!isAuthenticated && !userLocalStorage) {
    return <Navigate to="/admin/" />;
  }

  return element;
}
