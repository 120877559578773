import { Button, Col, Modal, Row } from "antd";
import React, { useState } from "react";
import BannerImage from "../../../../images/Driver/GroupBanner.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

type Props = {};

const NewBanner = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  return (
    <section className="sectionOne container mx-auto border-b-2 border-[#C6C6C6] pb-4">
      <Row gutter={[40, 16]}>
        <Col lg={{ span: 11, order: 2 }} sm={24}>
          <img src={BannerImage} alt="" className="h-auto max-w-full" />
        </Col>
        <Col lg={{ span: 13, order: 1 }} sm={24}>
          <div className="flex flex-col items-baseline justify-center h-full">
            <h1 className="font-Roboto text-FA0001 font-black sectionOneText">
              Be Our Delivery <br /> Partner Today
            </h1>
            <p className="font-black text-2xl my-2 font-Roboto w-full">
              Make Our Deliveries with your own schedule
            </p>
            <Button
              onClick={() => {
                dispatch({
                  type : "MODAL_OPEN"
                })
              }}
              className="bg-FA0001 ant-custom-btn border-FA0001 font-black text-white text-xl md:text-2xl md:px-4 uppercase w-full lg:w-fit rounded-xl h-14 mt-7"
            >
              Become Delivery Partner Now
            </Button>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default NewBanner;
