import React, { useRef, useState } from "react";
import classNames from "classnames";
import Logo from "../../images/LOGO/LOGO.png";
import { Link, useLocation } from "react-router-dom";
import { SideNavItem, NavItem } from "./SideNavItem";
import { ChevronDownIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";

// add NavItem prop to component prop
type Props = {
  collapsed: boolean;
  navItems?: NavItem[];
  setCollapsed(collapsed: boolean): void;
  shown: boolean;
};
const Sidebar = ({
  collapsed,
  navItems = SideNavItem,
  shown,
  setCollapsed,
}: Props) => {
  const location = useLocation();
  return (
    <div
      className={classNames({
        "bg-white shadow-md fixed md:static md:translate-x-0 z-20": true,
        "transition-all duration-300 ease-in-out": true,
        "w-[217px]": !collapsed,
        "w-16": collapsed,
        "-translate-x-full": !shown,
      })}
    >
      <div
        className={classNames({
          "flex flex-col justify-between h-screen md:h-full sticky inset-0":
            true,
        })}
      >
        <div
          className={classNames({
            "flex items-center  transition-none": true,
            "p-2 justify-between": !collapsed,
            "py-4 justify-center": collapsed,
          })}
        >
          {!collapsed && (
            <img src={Logo} alt="transitnow" className="h-24 mx-auto" />
          )}
        </div>
        <nav
          className={classNames({
            "flex-grow shadow-lg": true,
            "mt-7": collapsed,
          })}
        >
          <ul
            className={classNames({
              "my-2 flex flex-col gap-2 items-stretch p-0": true,
            })}
          >
            {navItems.map((item: any, index) => {
              const isActive = location.pathname === item.href;
              return (
                <li
                  key={index}
                  className={classNames({
                    "flex mb-3": true, //colors
                    "transition-colors duration-300 font-Poppins ": true, //animation
                    " me-3 gap-3 rounded-r-lg": !collapsed,
                    "rounded-full p-2 mx-3 w-10 h-10": collapsed,
                    "active-nav-bar": isActive && !collapsed,
                    "active-nav": isActive && collapsed,
                  })}
                >
                  <div
                    className={classNames({
                      "ms-2": !collapsed,
                    })}
                  >
                    {item.subItems ? (
                      // Render a dropdown if subItems are present
                      <Dropdown
                        label={!collapsed && item.label}
                        items={item.subItems}
                        icon={item.icon}
                        location={location.pathname}
                        collapsed={isActive && collapsed}
                        isCollapse={collapsed}
                      />
                    ) : (
                      // Render a regular link if no subItems
                      <Link
                        to={item.href}
                        className={classNames({
                          "flex gap-1 text-black active:!text-white font-Poppins text-xs items-center":
                            true,
                          "ms - 2": !collapsed,
                        })}
                      >
                        {item.icon}{" "}
                        <span className="mx-2">{!collapsed && item.label}</span>
                      </Link>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};

const Dropdown: React.FC<{
  label: string | any;
  items: NavItem[] | any;
  location: string;
  icon: any;
  collapsed: any;
  isCollapse:boolean;
}> = ({ label, items, location, icon, collapsed,isCollapse }) => {
  const [open, setOpen] = useState(false);

  const Icon = open ? ChevronDownIcon : ChevronLeftIcon;

  const toggleDropdown = () => {
    setOpen(!open);
  };
  const isActive = location === items.href;
  return (
    <>
      <p
        className={`flex gap-1 text-black active:!text-white mb-0  font-Poppins text-xs cursor-pointer`}
        onClick={toggleDropdown}
      >
        {icon}{" "}
        <span className="mx-2 flex justify-between items-center w-36">
          {label}{" "}
          <span
            className={classNames({
              "w-2 h-2 transform transition-transform": true,
              "rotate-180": open,
              "rotate-0": !open,
              hidden: collapsed,
            })}
          >
            <Icon />
          </span>
        </span>
      </p>
      <div
        className={classNames({
          "transition-dropdown": true,
          "dropdown-open": open && !isCollapse,
        })}
      >
        {" "}
        {open && (
          <ul
            className={classNames({
              "transition-all duration-300 ease-in-out-disc ms-3": open,
            })}
          >
            {items.map((item: any, index: any) => {
              return (
                <li
                  key={index}
                  className={classNames({
                    "my-2": true,
                    "active:bg-red active:border-black active:!text-white":
                      location === item.href,
                      "active-nav-bar": location === item.href,
                  })}
                >
                  <Link
                    to={item.href}
                    className="flex gap-2 text-black active:!text-white font-Poppins text-xs items-center"
                  >
                    {item.icon} <span className="mx-2">{item.label}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
};

export default Sidebar;
