import classNames from "classnames";
import React, { PropsWithChildren, useState } from "react";
import { Bars3Icon } from "@heroicons/react/24/outline";

import HeaderBar from "./HeaderBar";
import Sidebar from "./SideBar";

type Props = {};

const AdminLayouts = ({children}: PropsWithChildren) => {
  const [collapsed, setSidebarCollapsed] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  return (
    <div
      className={classNames({
        "grid min-h-screen": true,

        "grid-cols-sidebar sm:grids-cols-auto": !collapsed,
        "grid-cols-sidebar-collapsed": collapsed,
        "transition-[grid-template-columns] duration-300 ease-in-out": true,
      })}
    >
      {/* sidebar */}
      <Sidebar
        collapsed={collapsed}
        setCollapsed={() => setSidebarCollapsed((prev) => !prev)}
        shown={showSidebar}
      />
      {/* content */}
      <div className="flex flex-col justify-start">
        <HeaderBar
          collapsed={collapsed}
          setCollapsed={() => setSidebarCollapsed((prev) => !prev)}
          onMenuButtonClick={() => setShowSidebar((prev) => !prev)}
        />
        <div className="p-2">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdminLayouts;
