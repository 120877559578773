import React from 'react'
import { Col } from 'antd'
import Car from '../../../../images/driver.png'
import CountUp from 'react-countup';
type Props = {}

const TotalDrivers = (props: Props) => {
  return (
    <Col lg={12} xs={24} sm={12} xl={6}>
    <div className='dashboardSubPannels py-3 px-4'>
        <div className='flex justify-between w-full items-end mb-3'>
            <p className='font-bold text-xl mb-0'>Total Drivers</p>
            <img alt='' src={Car} className='h-10 w-10'/>
        </div>
        {/* <p className='font-bold text-3xl text-red mb-0'>172</p> */}
        <CountUp start={10} end={172} className='font-bold text-3xl text-red mb-0' />
    </div>
   </Col>
  )
}

export default TotalDrivers