import { Button, DatePicker, Form, Input, Select, message } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  VechileDriverFormInfo,
  VechileList,
} from "../../../../../interface/interface";
import {
  onChangeVehicle,
  onChangeVehicleLicense,
  onChangeVehicleModel,
  onChangeVehicleNumber,
  onChangeVehiclePermit,
  tabs,
} from "../../../../../actions/driverForm";
import { listVechile } from "../../../../../actions/vechileMapping";
import Arrow from "../../../../../images/Driver/downArrow.svg";
const VehicleInfo: React.FC<VechileDriverFormInfo> = () => {
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();
  const { driver, vehicles }: any = useSelector((state) => state);
  const { vechileList } = vehicles;
  const { currentTab, vehicleInfo } = driver;

  useEffect(() => {
    if (
      vehicleInfo &&
      Object.values(vehicleInfo).every((value) => value !== "")
    ) {
      form.setFieldsValue(vehicleInfo);
    }
  }, [currentTab, vehicleInfo]);
  return (
    <div className="py-4">
      <Form
        size="large"
        name="driverVehicleInfoForm"
        form={form}
        colon={true}
        // requiredMark="optional"
        layout="vertical"
        className="lg:grid lg:grid-cols-2 gap-x-12 gap-y-3"
        // labelAlign="left"
        labelCol={{
          span: 12,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={() => dispatch(tabs(2))}
      >
        <Form.Item
          name="vehicleType"
          rules={[
            {
              required: true,
              message: "A vehicle must be selected.",
            },
          ]}
        >
          <Select
            onChange={(value) => dispatch(onChangeVehicle(value))}
            className="text-xl font-Roboto font-normal vehicleType  h-full border-0"
            size="large"
            placeholder="Vehicle Type"
            suffixIcon={<img src={Arrow} alt="" className="me-3" />}
          >
            {vechileList?.map((vehicle: VechileList, index: number) => (
              <Select.Option key={index} value={vehicle.id}>
                {vehicle.vehicleName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="vehicleNumber"
          rules={[
            {
              required: true,
              message: "Vehicle Registration Number is required.",
            },
            {
              validator: (rule, value) => {
                const pattern = /^[A-Za-z0-9]{1,10}$/;
                if (!pattern.test(value)) {
                  return Promise.reject("Invalid vehicle number pattern.");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            onChange={(e) => {
              const value = e.target.value;
              dispatch(onChangeVehicleNumber(value));
            }}
            className="border-[#D3D2D2] bg-[#F4F4F4] border text-xl font-Roboto font-normal p-6"
            size="large"
            placeholder="Vehicle Registration Number"
          />
        </Form.Item>
        <Form.Item
          name="vehicleLicense"
          rules={[
            {
              required: true,
              message: "DL Number is required.",
            },
            {
              validator: (rule, value) => {
                const pattern = /^[A-Za-z0-9]{1,15}$/; // Customize the pattern as needed
                if (!pattern.test(value)) {
                  return Promise.reject("Invalid DL Number pattern.");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            onChange={(e) => {
              const value = e.target.value;
              dispatch(onChangeVehicleLicense(value));
            }}
            className="border-[#D3D2D2] bg-[#F4F4F4] border text-xl font-Roboto font-normal p-6"
            size="large"
            placeholder="DL Number"
          />
        </Form.Item>
        <Form.Item
          name="vehiclePermit"
          rules={[
            {
              required: false,
              message: "Vehicle Permit Number is required.",
            },
            {
              validator: (rule, value) => {
                const pattern = /^[A-Za-z0-9]{1,12}$/; // Customize the pattern as needed
                if (!pattern.test(value)) {
                  return Promise.reject(
                    "Invalid Vehicle Permit Number pattern."
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            onChange={(e) => {
              const value = e.target.value;
              dispatch(onChangeVehiclePermit(value));
            }}
            className="border-[#D3D2D2] bg-[#F4F4F4] border text-xl font-Roboto font-normal p-6"
            size="large"
            placeholder="Vehicle Permit Number"
          />
        </Form.Item>
        <Form.Item
          name="VehicleModel"
          rules={[
            {
              required: true,
              message: "Vehicle Model is required.",
            }
          ]}
        >
          <Input
            onChange={(e) => {
              const value = e.target.value;
              dispatch(onChangeVehicleModel(value));
            }}
            className="border-[#D3D2D2] bg-[#F4F4F4] border text-xl font-Roboto font-normal p-6"
            size="large"
            placeholder="Vehicle Model"
          />
        </Form.Item>
        <Form.Item
          // name="VehicleModel"
          // rules={[
          //   {
          //     required: false,
          //     message: "Vehicle Model is required.",
          //   }
          // ]}
        >
          {/* <Input
            onChange={(e) => {
              const value = e.target.value;
              dispatch(onChangeVehicleModel(value));
            }}
            className="border-[#D3D2D2] bg-[#F4F4F4] border text-xl font-Roboto font-normal p-6"
            size="large"
            placeholder="Vehicle Model"
          /> */}
        </Form.Item>
        <div className="flex justify-center lg:justify-end">
          <Button
            icon={<i className="text-[#9c9c9c] fa-solid fa-arrow-left"></i>}
            onClick={() => dispatch(tabs(0))}
            className=" bg-[#DEDEDE] ant-bnt-previous uppercase font-bold !text-2xl text-white !mt-2 !h-16 w-48 !rounded-xl hover:border-red"
          >
            Previous
          </Button>
        </div>
        <div className="flex justify-center lg:justify-start">
          <Button
            htmlType="submit"
            onClick={() => form.submit()}
            className="bg-red ant-custom-btn uppercase font-bold !text-2xl text-white !mt-2 !h-16 w-48 !rounded-xl hover:border-red"
          >
            Next
          </Button>
        </div>
      </Form>
      {/* <div className="w-full flex justify-center">
        <Button
          htmlType="submit"
          onClick={() => form.submit()}
          className="bg-red uppercase font-bold text-2xl text-white h-16 w-48 mt-2 rounded-xl hover:border-red"
        >
          Next
        </Button>
      </div> */}
    </div>
  );
};

export default VehicleInfo;
