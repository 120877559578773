import React from "react";
import { PublicFooter } from "./Footer";
import "./Public.scss";
import { Layout } from "antd";
import { PublicHeader } from "./Header";

type Props = {};

const PublicLayout = ({ children }: any) => {
  return (
    <Layout className="layout">
      <PublicHeader />
      <Layout.Content>
        <div className="site-layout-content bg-white ">
          <main className="py-4">{children}</main>
        </div>
      </Layout.Content>
      <PublicFooter />
    </Layout>
  );
};

export default PublicLayout;
