import { Col, Row } from "antd";
import React from "react";
import image1 from "../../../images/publicWebsites/section_Three/tracking.svg";
import image2 from "../../../images/publicWebsites/section_Three/order-tracking.svg";
import image3 from "../../../images/publicWebsites/section_Three/delivery.svg";
import image4 from "../../../images/publicWebsites/section_Three/chat.svg";
import image5 from "../../../images/publicWebsites/section_Three/api.svg";
import image6 from "../../../images/publicWebsites/section_Three/cod.svg";
import image7 from "../../../images/publicWebsites/section_Three/online-payment.svg";
import image8 from "../../../images/publicWebsites/section_Three/map.svg";
type Props = {};

const Section3 = (props: Props) => {
  return (
    <section className="border-b-4 border-b-black lg:py-16 py-10 container mx-auto">
      <h1 className="text-5xl lg:text-6xl font-extrabold">
        Accelerated{" "}
        <span className="font-extrabold text-5xl lg:text-6xl text-FA0001">Happiness</span>
      </h1>

      <Row
        wrap
        gutter={[24, 40]}
        className="xl:px-24 px-10 lg:mt-14 mt-10"
        justify={"center"}
      >
        <Col lg={6} sm={12} xs={24}>
          <div className="flex items-center justify-center flex-col">
            <div className="mb-4">
              <img src={image1} alt="" className="h-auto max-w-full"></img>
            </div>
            <p className="text-xl font-light w-[45%] text-center">Real-time fleet tracking</p>
          </div>
        </Col>
        <Col lg={6} sm={12} xs={24}>
        <div className="flex items-center justify-center flex-col">
            <div className="mb-4">
              {" "}
              <img src={image2} alt="" className="h-auto max-w-full"></img>
            </div> 
            <p className="text-xl font-light w-[95%] text-center">
              End-to-end shipment tracking and real-time ETAs
            </p>
          </div>
        </Col>
        <Col lg={6} sm={12} xs={24}>
        <div className="flex items-center justify-center flex-col">
            <div className="mb-4">
              {" "}
              <img src={image3} alt="" className="h-auto max-w-full"></img>
            </div>
            <p className="text-xl font-light w-[55%] text-center">Flexible delivery scheduling</p>
          </div>
        </Col>
        <Col lg={6} sm={12} xs={24}>
        <div className="flex items-center justify-center flex-col">
            <div className="mb-4">
              {" "}
              <img src={image4} alt="" className="h-auto max-w-full"></img>
            </div>
            <p className="text-xl font-light w-[80%] text-center">
              SMS / Email / WhatsApp notifications
            </p>
          </div>
        </Col>
        <Col lg={6} sm={12} xs={24}>
        <div className="flex items-center justify-center flex-col">
            <div className="mb-4">
              <img src={image5} alt="" className="h-auto max-w-full"></img>
            </div>
            <p className="text-xl font-light w-[65%] text-center">
              Multiple APIs and integration options
            </p>
          </div>
        </Col>
        <Col lg={6} sm={12} xs={24}>
        <div className="flex items-center justify-center flex-col">
            <div className="mb-4">
              {" "}
              <img src={image6} alt="" className="h-auto max-w-full"></img>
            </div>
            <p className="text-xl font-light w-[65%] text-center">COD and doorstep payments</p>
          </div>
        </Col>
        <Col lg={6} sm={12} xs={24}>
        <div className="flex items-center justify-center flex-col">
            <div className="mb-4">
              <img src={image7} alt="" className="h-auto max-w-full"></img>
            </div>
            <p className="text-xl font-light w-[60%] text-center">Electronic proof- of-delivery</p>
          </div>
        </Col>
        <Col lg={6} sm={12} xs={24}>
        <div className="flex items-center justify-center flex-col">
            <div className="mb-4">
              <img src={image8} alt="" className="h-auto max-w-full"></img>
            </div>
            <p className="text-xl font-light w-[106%] text-center">
              Designing zones and areas to manage high volumes efficiently and
              assign riders effectively
            </p>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Section3;
