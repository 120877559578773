import { Col } from 'antd'
import React from 'react'
import Earnings from '../../../../images/earnings.png'
import CountUp from 'react-countup';
type Props = {}

const TotalEarnings = (props: Props) => {
  return (
   <Col lg={12} xs={24} sm={12} xl={6}>
    <div className='dashboardSubPannels py-3 px-4'>
        <div className='flex justify-between w-full items-end mb-3'>
            <p className='font-bold text-xl mb-0'>Total Earnings</p>
            <img alt='' src={Earnings} className='h-10 w-10'/>
        </div>
        {/* <p className='font-bold text-3xl text-red mb-0'>RM 12452</p> */}
        <CountUp start={100} end={12452} prefix='RM ' className='font-bold text-3xl text-red mb-0' />
    </div>
   </Col>
  )
}

export default TotalEarnings