import React, { useState, useEffect } from "react";
import Logo from "../../../images/LOGO/LOGO.jpg";
import mobileLogo from "../../../images/LOGO/LOGO.png";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../config/firebase";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-phone-number-input/style.css'

type Props = {};

const ForgotPassword = (props: Props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if(message === 'Password reset email sent successfully.'){
  //       navigate("/login");
  //     }
  //   }, 3000);

  //   return () => clearTimeout(timer);
  // }, [message]);

  const handlePasswordReset = async (e: any) => {
    e.preventDefault();

    try {
      const reset = await sendPasswordResetEmail(auth, email, {
        url: `http://localhost:3000/login`,
      }).then(() => navigate('/login'));

      setMessage("Password reset email sent successfully.");
    } catch (error: any) {
      setMessage(`Error: ${error.message}`);
      console.log(error.message);
    }
  };
  return (
    <section className="vh-100">
      <div className="container-fluid h-100">
        <div className="row d-flex justify-content-center align-items-center h-100 white">
          <div className="col-md-6">
            <img
              src={Logo}
              className="img-fluid d-none d-md-block"
              alt="Sample"
            />
            <img
              src={mobileLogo}
              className="img-fluid d-block d-md-none"
              alt="Sample"
            />
          </div>
          <div className="col-md-6 h-100 brand-color">
            <div className="auth-wrapper">
              <div className="auth-inner">
                <form onSubmit={handlePasswordReset}>
                  <h3>Forgot Password</h3>
                  <div className="mb-3">
                    <label>Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {message && (
                      <div className="errorMsg mt-2">
                        <p>{message}</p>
                      </div>
                    )}
                  </div>

                  <div className="d-grid mt-4">
                    <button type="submit" className="btn btn-primary submit">
                      Submit
                    </button>
                  </div>
                  <p
                    className="text-center cursor-pointer forgot-password fw-bold"
                    onClick={() => navigate("/login")}
                  >
                    Back to Login
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
