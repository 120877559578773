import { Button, Divider, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import "../roles.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createRole, updateParticularRole, getParticularRole } from "../../../../../api/rolesAPI";

interface RoleData {
  id?: number;
  roleName?: string;
  description?: string;
  status?: boolean;
}

// Define the Props type explicitly to improve code readability
interface RolesAssignProps {}

const RolesAssign: React.FC<RolesAssignProps> = (props) => {
  const navigation = useNavigate();
  const [form] = Form.useForm();
  const currentLocation = useLocation();
  const { id } = useParams<{ id: string }>();
  const [roleData, setRoleData] = useState<RoleData>({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRoleData = async () => {
      try {
        const fetchedData = await getParticularRole(id);
        if (fetchedData) {
          setRoleData(fetchedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    // Only trigger the fetch data logic when the pathname includes "edit"
    if (currentLocation.pathname.includes("edit")) {
      fetchRoleData();
    }
  }, [id, currentLocation.pathname]);

  useEffect(() => {
    if (roleData) {
      form.setFieldsValue({
        roleName: roleData?.roleName,
        description: roleData?.description,
        status: roleData.status,
      });
    }
  }, [id, form, roleData]);

  const handleFormSubmission = async (values: RoleData) => {
    // Check if pathname includes "/admin/roles/edit/" for better readability
    const isEditMode = currentLocation.pathname.includes("/admin/roles/edit/");

    if (!isEditMode) {
      const createdData = await createRole(values);
      if (createdData) {
        toast.success("Role Created Successfully !", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        navigation(-1);
      }
    } else {
      const updatedValue = { ...roleData, ...values };
      const updatedData = await updateParticularRole(id, updatedValue);
      if (updatedData) {
        toast.success("Role Edited Successfully !", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        navigation(-1);
      }
      console.log(values);
    }
  };

  // Define the title based on the pathname
  let pageTitle = "Assign Roles";
  let buttonText = "Submit";

  if (currentLocation.pathname.includes("/admin/roles/edit/")) {
    pageTitle = "Edit Roles";
    buttonText = "Update"; // Change this to the desired title for the edit page
  }

  return (
    <section className="py-10 px-2 assign-roles">
      <div className="flex justify-between">
        <p className="text-3xl font-extrabold font-Poppins">{pageTitle}</p>
        <Button
          type="primary"
          onClick={() => navigation(-1)}
          className="roles-button w-32 !h-11"
          size="large"
          icon={<i className="fa-solid fa-arrow-left"></i>}
        >
          Go Back
        </Button>
      </div>
      <Divider />
      <div className="w-1/2 m-auto">
        <Form
          size="large"
          name="userForm"
          form={form}
          colon={false}
          requiredMark="optional"
          layout="vertical"
          // labelAlign="left"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 16,
          }}
          onFinish={handleFormSubmission}
        >
          <Form.Item
            label="Role Name"
            name="roleName"
            className="!text-base"
            rules={[
              {
                required: true,
                message: "The name is required.",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                const value = e.target.value;
              }}
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "The Description is required.",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                const value = e.target.value;
              }}
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: "The status is required.",
              },
            ]}
          >
            <Select>
              <Select.Option value={true}>Active</Select.Option>
              <Select.Option value={false}>In-active</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item className="mt-3">
            <div className="mt-4">
              <Button
                size="large"
                htmlType="submit"
                className="bg-green-500 text-white hover:!border-green-500 hover:border-1 w-32 !h-11"
              >
                {buttonText}
              </Button>
              <Button
                size="large"
                htmlType="reset"
                className="bg-gray-400 ms-4 text-white hover:!border-gray-400 hover:border-1 w-32 !h-11"
              >
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
};

export default RolesAssign;
