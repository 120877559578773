import React from "react";
import Section7 from "../Landing Page/Section7";
import Section8 from "../Landing Page/Section8";
import Banner from "./components/Banner";
import ChooseUs from "./components/ChooseUs";
import DriverForm from "./DriverForm";

type Props = {};

const Driver = (props: Props) => {
  return (
    <>
      <Banner />
      <DriverForm/>
      <ChooseUs/>
      <Section8 />
      <Section7 />
    </>
  );
};

export default Driver;
