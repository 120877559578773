import React from "react";
import image from "../../../../images/Driver/mini-truck.png";
import { Row, Col, Button } from "antd";
import playStore from "../../../../images/publicWebsites/playStore.png";
import appStore from "../../../../images/publicWebsites/AppStore.png";
import mobile from "../../../images/publicWebsites/Mobile.png";
type Props = {};

const MiniTruck = (props: Props) => {
  return (
    <section className="container mx-auto mt-5 lg:pb-20 pb-10 border-b-2 border-[#C6C6C6]">
      <Row gutter={[40, 24]}>
        <Col xl={12} lg={6} md={24} sm={24} xs={24}>
          <div className="h-full relative">
            <img src={image} alt="" className="h-full max-w-full absolute bottom-[-50px] left-[-10px]" />
          </div>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <div className="flex flex-col justify-center h-full items-start">
            <h1 className="text-6xl font-black font-Roboto">
              {/* Order, pick, <br className="hidden lg:block" />
              drop,{" "} */}
              Attach your <br />
              <span className="text-6xl font-black font-Roboto text-FA0001">
                mini truck or bike.
              </span>
            </h1>
            <p className="font-light text-black font-Roboto text-3xl my-4 leading-[47px] ">
              Earn money by delivering goods, courier and packages. Get a part
              time or full time delivery job Keeping track of your vehicle fleet
              and optimising their efficiency can be a huge challenge. Partner
              with us to boost your earnings and manage your vehicles easily and
              get a delivery job and deliver goods, packages, and courier.
            </p>
            <Button className="rounded-xl bg-FA0001 ant-custom-btn text-white font-black text-xl md:text-3xl w-full lg:w-fit leading-[47px] h-20 md:px-5 mt-2 ">
              Download Now Driver App Now
            </Button>{" "}
            <div className="mt-4">
              <div className="flex flex-wrap">
                <a href="#">
                  <img
                    src={playStore}
                    className="h-[52px] me-3 w-[147px]"
                    alt=""
                  />
                </a>
                <a href="#">
                  <img src={appStore} className="h-[52px]  w-full" alt="" />
                </a>
              </div>
            </div>
          </div>
        </Col>
        {/* <Col xl={6} lg={6} md={24} sm={24} xs={24} className="hidden xl:block">
          <img src={image} alt="" className="h-full max-w-full" />
        </Col> */}
      </Row>
    </section>
  );
};

export default MiniTruck;
