import { toast } from "react-toastify";
import {
  deleteVechilePricingRecord,
  getVechileList,
  getVehicleMappingDetails,
  insertPricingList,
  updateParticularPricingDetails,
} from "../api/vechileMapping";
import {
  LOADING,
  PRICE_MODAL,
  SERVICE_SELECTION,
  VECHILE_LIST_SUCCESS,
  VECHILE_MAPPING_DETAILS_SUCCESS,
  VECHILE_MAPPING_LIST,
} from "../constants/redux";
import { Modal } from "antd";
import { VEchileMappingList } from "../interface/interface";
import { serviceAction } from "./driverForm";

export const listVechile = () => async (dispatch: any, getState: any) => {
  try {
    const vechileList = await getVechileList();
    if (vechileList) {
      dispatch({
        type: VECHILE_LIST_SUCCESS,
        vechileList: vechileList,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const vechilePricing = () => async (dispatch: any, getState: any) => {
  try {
    const vechile_pricing = await getVehicleMappingDetails();
    if (vechile_pricing) {
      dispatch({
        type: VECHILE_MAPPING_DETAILS_SUCCESS,
        vechilePrizing: vechile_pricing,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const vechileMappingList =
  () => async (dispatch: any, getState: any) => {
    try {
      const { vehicles } = getState();
      const { vechileList, vechile_pricing, serviceTypeName } = vehicles;
      const removeObjects = (arr1: any, arr2: any) => {
        return arr1
          .filter(
            (obj1: any) =>
              !arr2.some(
                (obj2: any) =>
                  obj2.vehicleName === obj1.vehicleName &&
                  obj2.serviceTypeName === "Ride"
              )
          )
          .map(({ id: vehicleId, vehicleName }: any) => ({
            vehicleId,
            vehicleName,
          }));
      };
      const removeObjects2 = (arr1: any, arr2: any) => {
        return arr1
          .filter(
            (obj1: any) =>
              !arr2.some(
                (obj2: any) =>
                  obj2.vehicleName === obj1.vehicleName &&
                  obj2.serviceTypeName !== "Ride"
              )
          )
          .map(({ id: vehicleId, vehicleName }: any) => ({
            vehicleId,
            vehicleName,
          }));
      };

      // Call the function to remove objects
      const removingAlreadyFilledVechile = removeObjects(
        vechileList,
        vechile_pricing
      );
      const removingAlreadyFilledVechile2 = removeObjects2(
        vechileList,
        vechile_pricing
      );
      // console.log(removingAlreadyFilledVechile)
      dispatch({
        type: VECHILE_MAPPING_LIST,
        data: {
          ride: removingAlreadyFilledVechile,
          delivery: removingAlreadyFilledVechile2,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

export const addVechilePrice =
  (data: any) => async (dispatch: any, getState: any) => {
    try {
      const insertResponse = await insertPricingList(data);
      if (insertResponse) {
        toast.success("Price added Successfully !", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        dispatch({ type: PRICE_MODAL });
        dispatch(vechilePricing());
        setTimeout(() => {
          dispatch({ type: LOADING, loading: false });
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

export const deletePricingDetails =
  (record: any) => async (dispatch: any, getState: any) => {
    try {
      const deleteResponse = await deleteVechilePricingRecord(record);
      if (deleteResponse) {
        dispatch({ type: LOADING, loading: true });
        toast.success("Deleted Successfully !", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        dispatch(vechilePricing());
        setTimeout(() => {
          dispatch({ type: LOADING, loading: false });
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

export const updateVehiclePricingDetails =
  (record: VEchileMappingList[] | any) =>
  async (dispatch: any, getState: any) => {
    try {
      const updatedDataResponse = await updateParticularPricingDetails(
        record?.id,
        record
      );
      if (updatedDataResponse) {
        toast.success("Price updated Successfully !", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        dispatch({ type: PRICE_MODAL });
        dispatch(vechilePricing());
        setTimeout(() => {
          dispatch({ type: LOADING, loading: false });
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

export const handleServiceChange =
  (value = 1) =>
  async (dispatch: any, getState: any) => {
    const serviceData = getState();
    const data =
      value === 1
        ? {
            service_Type: 1,
            serviceTypeName: "Ride",
          }
        : {
            service_Type: 2,
            serviceTypeName: "Instant Delivery",
          };
    dispatch({
      type: SERVICE_SELECTION,
      service: data,
    });
  };
