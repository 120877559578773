import { _usersURL } from "../constants/api";
import { serviceInterceptor } from "./serviceInterceptor";

const headers = {
  "Content-Type": "application/json",
};
const handleResponse = async (response) => {
  if (!response.ok) {
    const errorBody = await response.json();
    console.error("Bad Request Error:", response.status, errorBody);
    throw new Error(errorBody.message || "Request failed");
  }
  return response.json();
};

export const getAllUsers = () => serviceInterceptor(`${_usersURL}/GetAllUsers`, "GET");

export const deleteUser = (record) =>
  serviceInterceptor(`${_usersURL}/RemoveUser/${record.id}`, "DELETE", record);

export const CreateUsers = async (users) => {
  try {
    console.log("first")
    const usersadd = {
      Id: 0,
      Created_By: 1,
      Modified_By: 1,
      Is_Deleted: false,
    };
    const status = {
      ...users,
      Status: users.status === "false" ? 0 : 1,
    };
    const usersWithCreatedBy = { ...users, ...usersadd };
    // console.log(roleWithCreatedBy);
    const userString = localStorage.getItem("user");
    var token = null;

    // if (userString && userString.trim() !== "") {
    //   try {
    //     token = JSON.parse(userString);
    //     const updatedHeaders = {
    //       ...headers,
    //       Authorization: token ? `Bearer ${token.token}` : null,
    //     };
    
    //     const response = await fetch(`${_usersURL}/CreateUser`, {
    //       method: "POST",
    //       body: JSON.stringify(usersWithCreatedBy),
    //       updatedHeaders,
    //     });
    //     console.log("first ==> ",response)
    //     return handleResponse(response);
    //     // return token;
    //   } catch (error) {
    //     console.error("Error parsing user token:", error);
    //     // Handle the parsing error if needed
    //   }
    // }
   return serviceInterceptor(`${_usersURL}/CreateUser`,"POST",usersWithCreatedBy)
  } catch (error) {
    console.error("Error in createUser:", error);
    throw error;
  }
};

export const getParticularUser = (id) =>
  serviceInterceptor(`${_usersURL}/GetUserById/${id}`, "GET");

export const updateParticularUser = (id, data) =>
  serviceInterceptor(`${_usersURL}/ModifyUser/${id}`, "PUT", data);
