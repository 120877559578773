import { deleteRole, getAllRoles } from "../api/rolesAPI";
import {
    DELETE_ROLE_SUCCESS,
  ROLE_PERMISSION_LOADING,
  ROLE_PERMISSION_SUCCESS,
} from "../constants/redux";

export const listRoles = () => async (dispatch) => {
  try {
    dispatch({type : ROLE_PERMISSION_LOADING})
    const data = await getAllRoles();
    console.log(data.length)
    if (data) {
        dispatch({
        type: ROLE_PERMISSION_SUCCESS,
        roles_permission: data,
        length : data.length
      });
    }
  } catch (error) {}
};

export const deleteRoleSuccess = (roleId) => async(dispatch) =>{
    const data = await deleteRole(roleId)
    console.log(data)
    dispatch({
      type : DELETE_ROLE_SUCCESS,
      roleID : roleId
  })
}