// components/defaultNavItems.tsx
import React from "react";
import {
  BuildingOffice2Icon,
  CalendarIcon,
  DocumentIcon,
  EyeIcon,
  FolderIcon,
  HomeIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import ManageRiders from "../../images/fare.png";
import ManageDrivers from "../../images/customer-review.png";
import Docs from "../../images/google-docs.png";
import Fleet from "../../images/fleet-management.png";
import Distenation from "../../images/destination.png";
import HRM from "../../images/hrm.png";
import salary from "../../images/salary.png";
import customerService from "../../images/customer-service.png";
import megaPhone from "../../images/megaphone.png";
import pushNotification from "../../images/smartphone.png";
import Taxi from "../../images/taxi.png";
import Coupons from "../../images/coupon.png";
import Reports from "../../images/business-report.png";
import manager from "../../images/manager.png";
import settings from "../../images/settings.png";
// define a NavItem prop
export type NavItem = {
  label: string;
  href?: string;
  icon: React.ReactNode;
  subItems?: [] | any;
};

export const SideNavItem: NavItem[] = [
  {
    label: "Dashboard",
    href: "/admin/dashboard",
    icon: <HomeIcon className="w-5 h-5" />,
  },
  {
    label: "Customer",
    href: "/admin/customer",
    icon: <UserGroupIcon className="w-5 h-5" />,
  },
  {
    label: "Manage Drivers",
    icon: <img src={ManageRiders} alt="riders" className="w-5 h-5" />,
    subItems: [
      {
        label: "Approved",
        href: "/admin/manage-drivers/approved",
      },
      {
        label: "Pending",
        href: "/admin/manage-drivers/pending",
      },
      {
        label: "Rejected",
        href: "/admin/manage-drivers/rejected",
      },
    ],
  },
  // {
  //   label: "God's Eye",
  //   href: "/admin//gods-eye",
  //   icon: <EyeIcon className="w-5 h-5" />,
  // },
  {
    label: "Security",
    icon: <UserGroupIcon className="w-5 h-5" />,
    subItems: [
      {
        label: "Users",
        href: "/admin/users",
      },
      {
        label: "Roles",
        href: "/admin/roles",
      },
    ],
  },
  {
    label: "Master",
    icon: <UserGroupIcon className="w-5 h-5" />,
    subItems: [
      {
        label: "Vehicles-mapping",
        href: "/admin/vehicles-mapping",
      },
    ],
  },
  // {
  //   label: "Manage Company",
  //   href: "/company",
  //   icon: <BuildingOffice2Icon className="w-5 h-5" />,
  // },
  // {
  //   label: "Manage Drivers",
  //   href: "/drivers",
  //   icon: <img src={ManageRiders} alt='riders' className="w-5 h-5" />,
  // },
  // {
  //   label: "Manage Riders",
  //   href: "/riders",
  //   icon: <img src={ManageDrivers} alt='riders' className="w-5 h-5" />,
  // },
  // {
  //   label: "Manage Documents",
  //   href: "/doc",
  //   icon: <img src={Docs} alt='riders' className="w-5 h-5" />,
  // },
  // {
  //   label: "Vehicle Managements",
  //   href: "/vehicle-management",
  //   icon: <img src={Fleet} className="w-5 h-5" />,
  //   subItems :[{
  //       label: "Admin users",
  //   href: "/admin-users",
  //   },{
  //       label: "Roles & Permissions",
  //   href: "/roles",
  //   }]
  // },
  // {
  //   label: "Trip Managements",
  //   href: "/trip",
  //   icon: <img src={Distenation} className="w-5 h-5" />,
  // },
  // {
  //   label: "HRM",
  //   href: "/hrm",
  //   icon: <img src={HRM} className="w-5 h-5" />,
  //   subItems :[{
  //       label: "Admin users",
  //   href: "/admin-users",
  //   },{
  //       label: "Roles & Permissions",
  //   href: "/roles",
  //   }]
  // },
  // {
  //   label: "Payroll",
  //   href: "/payroll",
  //   icon: <img src={salary} className="w-5 h-5" />,
  //   subItems :[{
  //       label: "Admin users",
  //   href: "/admin-users",
  //   },{
  //       label: "Roles & Permissions",
  //   href: "/roles",
  //   }]
  // },{
  //   label: "Support Ticket",
  //   href: "/tickets",
  //   icon: <img src={customerService} className="w-5 h-5" />,
  //   subItems :[{
  //       label: "Admin users",
  //   href: "/admin-users",
  //   },{
  //       label: "Roles & Permissions",
  //   href: "/roles",
  //   }]
  // },
  // {
  //   label: "Marketing",
  //   href: "/marketing",
  //   icon: <img src={megaPhone} className="w-5 h-5" />,
  //   subItems :[{
  //       label: "Admin users",
  //   href: "/admin-users",
  //   },{
  //       label: "Roles & Permissions",
  //   href: "/roles",
  //   }]
  // },
  // {
  //   label: "Push notification",
  //   href: "/notifications",
  //   icon: <img src={pushNotification} className="w-5 h-5" />,
  //   subItems :[{
  //       label: "Admin users",
  //   href: "/admin-users",
  //   },{
  //       label: "Roles & Permissions",
  //   href: "/roles",
  //   }]
  // },
  // {
  //   label: "Fare Management",
  //   href: "/fare-management",
  //   icon: <img src={Taxi} className="w-5 h-5" />,

  // },
  // {
  //   label: "Coupons",
  //   href: "/coupons",
  //   icon: <img src={Coupons} className="w-5 h-5" />,

  // },
  // {
  //   label: "Reports",
  //   href: "/reports",
  //   icon: <img src={Reports} className="w-5 h-5" />,

  // },
  // {
  //   label: "Manage Payouts",
  //   href: "/payouts",
  //   icon: <img src={manager} className="w-5 h-5" />,
  //   subItems :[{
  //       label: "Admin users",
  //   href: "/admin-users",
  //   },{
  //       label: "Roles & Permissions",
  //   href: "/roles",
  //   }]
  // },
  // {
  //   label: "Settings",
  //   href: "/settings",
  //   icon: <img src={settings} className="w-5 h-5" />,

  // },
];
