import React, { useState, useEffect } from "react";
import {
  driverFormApproval,
  getAllDrivers,
  getFileType,
} from "../../../api/manageDriversAPI";
import {
  Table,
  Tag,
  Space,
  Tooltip,
  Avatar,
  Button,
  Modal,
  Form,
  Input,
  Divider,
} from "antd";
import { getRandomColor } from "../../../config/randomColor";
import { TablePaginationConfig } from "antd/es/table";
import { ColumnsType } from "antd/es/table";
import { ReactNode } from "react";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { __manageFileType } from "../../../constants/api";
import { authToken } from "../../../api/serviceInterceptor";
import { toast } from "react-toastify";
import { handleFileDownloadResponse } from "../../../actions/driverForm";

interface Driver {
  name: string;
  photoFileName?: string;
  email: string;
  mobileNo: string;
  address: string;
  files: string;
  status: "pending" | "accepted" | "rejected";
  reason: string;
}

type ManageDriversProps = {};

const ManageDrivers: React.FC<ManageDriversProps> = () => {
  const [drivers, setDrivers] = useState<Driver[] | undefined>([]);
  const [loading, setLoading] = useState(true);
  const [tableParams, setTableParams] = useState<{
    pagination: TablePaginationConfig | any;
  }>({
    pagination: {
      current: 1,
      pageSize: 10,
      position: "bottomRight",
    },
  });
  const [btnloading, setbtnLoading] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const handleFileDownload = async (id: any, type: any, fileName: any) => {
    try {
      const token: any = authToken();
      setbtnLoading(true);
      const response: Response = await fetch(
        `${__manageFileType}/${id}/${type}`,
        {
          method: "GET",
          headers: {
            Authorization: token ? `Bearer ${token.token}` : "",
          },
        }
      );
      handleFileDownloadResponse(response,fileName)
    } catch (error) {
      // Log an error message if an exception occurs during the fetch operation
      console.error("Error fetching data:", error);
    }
  };
  const fetchData = async () => {
    try {
      const response = await getAllDrivers();

      let filteredDrivers;

      if (Array.isArray(response)) {
        filteredDrivers = response.filter(
          (driver) => driver.status !== "Approved" && driver.status !== "Reject"
        );
      } else {
        const driversArray: any = Object.values(response);
        filteredDrivers = driversArray.filter(
          (driver: any) =>
            driver.status !== "Approved" && driver.status !== "Reject"
        );
      }

      setDrivers(filteredDrivers);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching driver data:", error);
      setbtnLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        total: drivers?.length,
      },
    }));
  }, [drivers]);

  const customPagination = {
    ...tableParams.pagination,
    onChange: (page: number) => {
      setTableParams((prevTableParams) => ({
        ...prevTableParams,
        pagination: {
          ...prevTableParams.pagination,
          current: page,
        },
      }));
    },
  };
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [modalcontent, setModalcontent] = useState({
    status: "",
    id: null,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleButtonClick = (status: any, id: any) => {
    form.resetFields();
    form.setFieldsValue({ status });
    setModalcontent({
      status: status,
      id: id,
    });
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    form.resetFields();
  };
  const columns: ColumnsType<Driver> = [
    {
      title: "Drivers",
      dataIndex: "drivers",
      render: (text: any, record: any) => (
        <div className="flex">
          <div>
            {record.photoFileName ? (
              <Avatar src={record.photoFileName} alt={record.name} />
            ) : (
              <Avatar
                style={{ backgroundColor: getRandomColor() }}
                className="custom-avatar"
                size="large"
              >
                <span>{record.name.charAt(0).toUpperCase()}</span>
              </Avatar>
            )}
          </div>
          <div className="ms-3">
            <p className="mb-0 text-base">
              <strong>{record.name}</strong>
            </p>
            <p className="text-xs text-balance">{record.email}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "mobileNo",
    },
    {
      title: "Address",
      dataIndex: "address",
      render: (address: string, record: Driver, index: number) => {
        return (
          <p
            className={`text-sm ${
              hoveredIndex === index ? "" : "truncate"
            } max-w-[150px]`}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            {address}
          </p>
        );
      },
    },
    {
      title: "GovtID",
      dataIndex: "govtID",
      render: (text: any, record: any) => (
        <div className="flex items-center justify-center">
          {/* <p className="mr-2">Download</p> */}
          <Button
            onClick={() => handleFileDownload(record?.id, "GP", "GovtIDProof")}
            icon={
              <i className="fa-solid fa-download" style={{ color: "black" }} />
            }
            className="border-none"
          />
        </div>
      ),
    },
    {
      title: "Vehicle Photo",
      dataIndex: "govtID",
      render: (text: any, record: any) => (
        <div className="flex items-center justify-center">
          {/* <p className="mr-2">Download</p> */}
          <Button
            onClick={() =>
              handleFileDownload(record?.id, "VP", "Vehicle photo")
            }
            icon={
              <i className="fa-solid fa-download" style={{ color: "black" }} />
            }
            className="border-none"
          />
        </div>
      ),
    },
    {
      title: "RC Book",
      dataIndex: "rcbook",
      render: (text: any, record: any) => (
        <div className="flex items-center justify-center">
          {/* <p className="mr-2">Download</p> */}
          <Button
            onClick={() => handleFileDownload(record?.id, "RCB", "RC Book")}
            icon={
              <i className="fa-solid fa-download" style={{ color: "black" }} />
            }
            className="border-none"
          />
        </div>
      ),
    },
    {
      title: "Insurance",
      dataIndex: "govtID",
      render: (text: any, record: any) => (
        <div className="flex items-center justify-center">
          {/* <p className="mr-2">Download</p> */}
          <Button
            onClick={() => handleFileDownload(record?.id, "IS", "Insurance")}
            icon={
              <i className="fa-solid fa-download" style={{ color: "black" }} />
            }
            className="border-none"
          />
        </div>
      ),
    },
    {
      title: "Driver License",
      dataIndex: "driverLicense",
      render: (text: any, record: any) => (
        <div className="flex items-center justify-center">
          {/* <p className="mr-2">Download</p> */}
          <Button
            onClick={() =>
              handleFileDownload(record?.id, "DL", "Driver_License")
            }
            icon={
              <i className="fa-solid fa-download" style={{ color: "black" }} />
            }
            className="border-none"
          />
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: any) => {
        return (
          <span>
            <Tag
              color={
                status === "pending"
                  ? "orange"
                  : status === "Approved"
                  ? "green"
                  : "red"
              }
            >
              {status}
            </Tag>
          </span>
        );
      },
    },
    // {
    //   title: "Reason",
    //   dataIndex: "reason",
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (_text: any, record: Driver | any): ReactNode => (
        <Space>
          <Tooltip title="Approve">
            <Button
              className="border-none"
              onClick={() => handleButtonClick("Approved", record?.id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="green"
                className="w-6 h-6 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m4.5 12.75 6 6 9-13.5"
                />
              </svg>
            </Button>
          </Tooltip>
          <Tooltip title="Reject">
            <Button
              className="border-none"
              onClick={() => handleButtonClick("Reject", record?.id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="red"
                className="w-6 h-6 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();
  return (
    <section className="py-10 px-2 manageDrivers">
      <div className="flex justify-between">
        <p className="text-3xl font-bold">Drivers</p>
      </div>
      <div className="py-3">
        <Table
          size="small"
          className="driverTable"
          columns={columns}
          dataSource={drivers}
          loading={loading}
          pagination={customPagination}
        />
      </div>
      <Modal
        title={
          <h3 className="font-Poppins font-bold">{`${modalcontent.status} `}</h3>
        }
        width={900}
        open={isModalVisible}
        footer={
          <>
            <Divider />
            <Button
              size="large"
              loading={confirmLoading}
              className="bg-green-500 w-32 !h-11 text-white border-green-500"
              onClick={() => form.submit()}
            >
              {modalcontent.status}
            </Button>
          </>
        }
        onCancel={handleModalClose}
      >
        <div className="py-4">
          <Form
            size="large"
            name="driverPersonalInfoForm"
            form={form}
            colon={true}
            // requiredMark="optional"
            layout="vertical"
            className=""
            // labelAlign="left"
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 24,
            }}
            onFinish={async (value) => {
              setConfirmLoading(true);
              const data = {
                status: value.status,
                id: modalcontent.id,
                reason: value.reason === "" ? "" : value.reason,
              };
              const response = await driverFormApproval(modalcontent.id, data);
              if (response) {
                setConfirmLoading(false);
                toast.success(`Driver form has been ${modalcontent.status}`);
                setIsModalVisible(false);
                fetchData();
              }
            }}
          >
            <Form.Item
              name="status"
              label="Status"
              // rules={[
              //   {
              //     required: true,
              //     message: "The First Name is required.",
              //   },
              // ]}
            >
              <Input
                disabled={true}
                onChange={(e) => {
                  const value = e.target.value;
                  // dispatch(onChangeFirstName(value));
                }}
                className="border-[#D3D2D2] border text-xl font-Roboto font-normal p-3"
                size="large"
                placeholder="Status"
              />
            </Form.Item>
            <Form.Item
              name="reason"
              label="Reason"
              rules={[
                {
                  required: modalcontent.status === "Approved" ? false : true,
                  message: "The reason is required.",
                },
              ]}
            >
              <Input.TextArea
                onChange={(e) => {
                  const value = e.target.value;
                  // dispatch(onChangeFirstName(value));
                }}
                className="border-[#D3D2D2] border text-xl font-Roboto font-normal p-3"
                size="large"
                placeholder="Reason"
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </section>
  );
};

export default ManageDrivers;
