import { DELETE_ROLE_SUCCESS, ROLE_PERMISSION_LOADING, ROLE_PERMISSION_SUCCESS } from "../constants/redux";

export const rolesListReducer = (state = {roles_Permission :[]},action) =>{
    switch(action.type){
        case ROLE_PERMISSION_LOADING:
            return{
                loading : true,
                roles_permission : [],
                roles_length :0
            }
        
        case ROLE_PERMISSION_SUCCESS:
            return{
                loading : false,
                roles_permission : action.roles_permission,
                roles_length : action.length
            }
            case DELETE_ROLE_SUCCESS:
                const deletedRoleId = action.roleID;
                // Remove the role with the given ID from the state
                const updatedRolesPermission = state.roles_permission.filter(
                  (role) => role.id !== deletedRoleId
                );
                console.log(updatedRolesPermission)
                return {
                  ...state,
                  roles_permission: updatedRolesPermission,
                  roles_length: state.roles_length, // Adjust the length accordingly
                };
          
        default:
            return state
    }
}