import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePricingDetails,
  listVechile,
  vechileMappingList,
  vechilePricing,
} from "../../../../actions/vechileMapping";
import {
  VEchileMappingList,
  VechileList,
} from "../../../../interface/interface";
import moment from "moment";
import { Button, Popconfirm, Table, Tooltip, Divider, Modal, Form } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import VehicleMappingForm from "../components/VehicleMappingForm";
import {
  LOADING,
  PRICE_MODAL,
  SELECTED_VEHICLE_TYPE,
  VECHILE_MAPPING_ID,
} from "../../../../constants/redux";
import { getParticularPriceDetails } from "../../../../api/vechileMapping";
import PriceModalContent from "../components/PricingForm";
import { serviceAction } from "../../../../actions/driverForm";
type Props = {};

interface VechileState {
  vehicles: {
    vechileList: VechileList[];
    vechile_pricing: VEchileMappingList[];
    vehicleMappingList: { vehicleId: number; vehicleName: string }[];
    selectedVehicleList: { vehicleId: number; vehicleName: string };
    modal: boolean;
    loading: boolean;
  };
}

const VechileMapping: React.FC<Props> = (props: Props) => {
  const navigation = useNavigate();
  const dispatch: any = useDispatch();
  const { vehicles }: VechileState = useSelector((state: any) => state);
  // const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const [modalTitle, setModalTitle] = React.useState<string>("Assign");
  const [buttonText, setButtonText] = React.useState<string>("Submit");
  const [form] = Form.useForm();
  const [step, setStep] = React.useState<number>(1);
  const [selectedVehicle, setSelectedVehicle] = React.useState<
    number | string | null
  >(null);
  const [selectedService, setSelectedService] = React.useState<
    number | string | null
  >(null);
  const [alreadyFilledData, setAlreadyFilledData] = React.useState<any>({
    basePrice: null,
    perKMPrice:null,
    waitingCharge:null,
    peakHoursPrice:null
  })

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([dispatch(listVechile()), dispatch(vechilePricing())]);

      setTimeout(() => {
        dispatch({ type: LOADING, loading: false });
      }, 1000);
    };
    fetchData();
    // dispatch(serviceAction())
  }, [dispatch]);

  useEffect(() => {
    if (vehicles?.vechile_pricing) {
      dispatch(vechileMappingList());
    }
  }, [vehicles?.vechile_pricing, dispatch]);

  useEffect(() => {
    if (vehicles?.modal) {
      dispatch(listVechile());
      dispatch(vechilePricing());
    }
  }, [vehicles?.modal, dispatch]);

  useEffect(() => {
    const selectedVehicleData =
      vehicles?.vechileList
        .filter((vehicle) => vehicle.id === selectedVehicle)
        .map(({ id: vehicleId, vehicleName }: any) => ({
          vehicleId,
          vehicleName,
        })) || {};

    dispatch({
      type: SELECTED_VEHICLE_TYPE,
      data: selectedVehicleData[0],
    });
  }, [selectedVehicle, vehicles?.vechileList, dispatch]);

  const handleNext = async () => {
    try {
      await form.validateFields(["vehicleName"]);
      if (!selectedVehicle) {
        return;
      }
      setStep(step + 1);
    } catch (errorInfo) {
      console.log("Validation failed:", errorInfo);
    }
  };

  const columns = [
    {
      title: "Vechile Name",
      dataIndex: "vehicleName",
    },
    {
      title: "Service",
      dataIndex: "serviceTypeName",
      
    },
    {
      title: "Base Price",
      dataIndex: "basePrice",
    },
    {
      title: "km/h Price",
      dataIndex: "perKMPrice",
    },
    {
      title: "Peak Hours Price",
      dataIndex: "peakHoursPrice",
    },
    {
      title: "Waiting Charges",
      dataIndex: "waitingCharge",
    },
    {
      title: "Created Date",
      dataIndex: "created_Date",
      render: (createdDate: string | any) =>
        moment(createdDate).format("MMMM Do YYYY"),
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_: any, record: any) => (
        <div>
          {renderEditIcon(record)}
          {renderDeleteIcon(record)}
        </div>
      ),
    },
  ];
  useEffect(() => {
    // dispatch(serviceAction());
  }, []);
  const renderEditIcon = (record: any) => (
    <Tooltip title="Edit" color={"green"}>
      <EditOutlined
        className="text-green-500 text-lg me-4"
        onClick={() => {
          setButtonText("Update");
          setModalTitle("Update");
          dispatch({ type: PRICE_MODAL });
          setSelectedService(record?.serviceTypeName)
          setSelectedVehicle(record?.vehicleId);
          setAlreadyFilledData({
            basePrice : record?.basePrice,
            waitingCharge:record?.waitingCharge,
            peakHoursPrice:record?.peakHoursPrice,
            perKMPrice:record?.perKMPrice
          })
          dispatch({ type: VECHILE_MAPPING_ID, id: record?.id });
          setStep(2);
        }}
      />
    </Tooltip>
  );

  const renderDeleteIcon = (record: any) => (
    <Popconfirm
      title="Delete the price"
      description="Are you sure to delete this?"
      className="roles-pop"
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      onConfirm={() => dispatch(deletePricingDetails(record))}
      okText="Delete"
      okButtonProps={{
        className: "custom-ok-button bg-red text-white hover:!bg-red",
      }}
    >
      <DeleteOutlined className="text-red text-lg" />
    </Popconfirm>
  );

  return (
    <section className="py-10 px-2 vechile-pricing-list roles-permission">
      <div className="flex justify-between">
        <p className="text-3xl font-Poppins font-extrabold">
          Vehicles Pricing List
        </p>
        <Button
          type="primary"
          onClick={() => {
            dispatch({ type: PRICE_MODAL });
            setStep(1);
            form.resetFields();
          }}
          className="roles-button bg-[#0A89FF] w-44 !h-12 !font-extrabold !text-xl font-Poppins"
          size="large"
          icon={<i className="fa-solid fa-plus"></i>}
          disabled={Object.keys(vehicles?.vehicleMappingList).length ? false : true}
        >
          Assign Price
        </Button>
      </div>
      <Divider />
      <div className="py-3">
        <Table
          size="large"
          className="transit-custom-table"
          columns={columns}
          dataSource={vehicles?.vechile_pricing}
          loading={vehicles?.loading}
        />
      </div>
      <Modal
        title={
          <h3 className="font-Poppins font-bold">{`${modalTitle} Vehicle Price`}</h3>
        }
        open={vehicles?.modal}
        footer={
          step === 1 ? (
            <>
              <Divider />
              <Button
                size="large"
                className="bg-green-500 w-32 !h-11 text-white border-green-500"
                onClick={handleNext}
              >
                Next
              </Button>
            </>
          ) : (
            <>
              <Divider />
              {/* <Button onClick={handleGoBack}>Go Back to Select Option</Button> */}
              <Button
                size="large"
                htmlType="submit"
                onClick={() => form.submit()}
                className="bg-green-500 w-32 !h-11 text-white border-green-500"
              >
                {buttonText}
              </Button>
            </>
          )
        }
        width={900}
        onCancel={() => {
          dispatch({ type: PRICE_MODAL });
          form.resetFields();
          setStep(1);
        }}
      >
        <PriceModalContent
          step={step}
          vehicles={vehicles}
          selectedVehicle={selectedVehicle}
          setSelectedService={setSelectedService}
          selectedService={selectedService}
          setSelectedVehicle={setSelectedVehicle}
          filledPrice={alreadyFilledData}
          buttonText={buttonText}
          form={form}
          handleGoBack={() => {
            // form.resetFields()
            setStep(step - 1);
          }}
        />
      </Modal>
    </section>
  );
};

export default VechileMapping;
