import React from "react";
import { Button, Col, Modal, Row } from "antd";
type Props = {};

const WhyBe = (props: Props) => {
  return (
    <section className="container mx-auto mt-3 lg:pb-20 pb-10 border-b-2 border-[#C6C6C6]">
      <p className="text-6xl text-black mb-5 font-black text-center font-Roboto">
        Why Be Our Partner?
      </p>
      <Row gutter={[40, 48]}>
        <Col xl={6} md={12} sm={24} xs={24}>
          <div className="shadow h-[222px] flex flex-col justify-center py-5 px-4  rounded-xl">
            <p className="text-FA0001 font-black text-xl text-center font-Roboto">
              Be your own Boss
            </p>
            <p className="text-black font-normal text-sm text-center font-Roboto">
              flexible delivery schedule to earn unlimited income
            </p>
          </div>
        </Col>
        <Col xl={6} md={12} sm={24} xs={24}>
          <div className="shadow  flex flex-col justify-center h-[222px] py-5 px-4  rounded-xl">
            <p className="text-FA0001 font-black text-xl text-center font-Roboto">
              No more commission
            </p>
            <p className="text-black font-normal text-sm text-center font-Roboto">
              Just Pay Daily or Monthly Fixed App fees
            </p>
          </div>
        </Col>
        <Col xl={6} md={12} sm={24} xs={24}>
          <div className="shadow  flex flex-col justify-center h-[222px] py-5 px-4  rounded-xl">
            <p className="text-FA0001 font-black text-xl text-center font-Roboto">
              Free Smartphone
            </p>
            <p className="text-black font-normal text-sm text-center font-Roboto">
              Free Smartphone for eligible Driver partners
            </p>
          </div>
        </Col>
        <Col xl={6} md={12} sm={24} xs={24}>
          <div className="shadow  flex flex-col justify-center h-[222px] py-5 px-4  rounded-xl ">
            <p className="text-FA0001 font-black text-xl text-center font-Roboto">
              Insurance
            </p>
            <p className="text-black font-normal text-sm text-center font-Roboto">
              Free Personal Accident Insurance
            </p>
          </div>
        </Col>
      </Row>
      <Row justify="center" gutter={[40, 48]} className="mt-5">
        <Col xl={6} md={12} sm={24} xs={24}>
          <div className="shadow flex flex-col justify-center h-[222px] py-5 px-4  rounded-xl">
            <p className="text-FA0001 font-black text-xl text-center font-Roboto">
              Allowance
            </p>
            <p className="text-black font-normal text-sm text-center font-Roboto">
              Food/Meal Voucher or Allowance
            </p>
          </div>
        </Col>
        <Col xl={6} md={12} sm={24} xs={24}>
          <div className="shadow  flex flex-col justify-center py-5 px-4 h-[222px]  rounded-xl">
            <p className="text-FA0001 font-black text-xl text-center font-Roboto">
              Incentive & Voucher
            </p>
            <p className="text-black font-normal text-sm text-center font-Roboto">
              Rider Get Rider Incentive and MonthlyFuel Voucher
            </p>
          </div>
        </Col>
        <Col xl={6} md={12} sm={24} xs={24}>
          <div className="shadow  flex flex-col justify-center h-[222px] py-5 px-4  rounded-xl">
            <p className="text-FA0001 font-black text-xl text-center font-Roboto">
              Benefits & Rewards
            </p>
            <p className="text-black font-normal text-sm text-center font-Roboto">
              Exclusive benefits and rewards will be update time to time.
            </p>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default WhyBe;
