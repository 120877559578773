import { Menu, Drawer, Button, Layout } from "antd";
import React, { useState, useEffect } from "react";
import Logo from "../../images/LOGO/LOGO.png";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

export const PublicHeader= () => {
  const [current, setCurrent] = useState("mail");
  const navigate: any = useNavigate();
  const [visible, setVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    // Check the screen width and set isMobile accordingly
    setIsMobile(window.innerWidth <= 1024); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const menuMode = isMobile ? "vertical" : "horizontal";
  const drawerStyle = window.innerWidth < 1024 ? { height: "100vh" } : {};

  const { Header } = Layout;

  return (
    <Header
      className="menuBar  xl:flex bg-white customlayouts"
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div className="logo">
        <Link to="/">
          <img src={Logo} alt="transit now" />
        </Link>
      </div>
      <div className="menuCon xl:items-center xl:justify-evenly xl:flex">
        <div className="leftMenu xl:w-[60%] flex items-center">
          <LeftMenu mode={menuMode} />
        </div>
        <div className="rightMenu">
          <RightMenu mode={menuMode} />
        </div>
        <Button className="barsMenu" type="primary" onClick={showDrawer}>
          <span className="barsBtn"></span>
        </Button>
        <Drawer
          title={
            <span className="font-normal text-base flex justify-between">
              {" "}
              Transit now
              <CloseCircleOutlined onClick={onClose} className="text-sm" />
            </span>
          }
          placement="right"
          closable={false}
          onClose={onClose}
          open={visible}
          style={drawerStyle}
        >
          <LeftMenu mode={menuMode} />
          <RightMenu mode={menuMode} />
        </Drawer>
      </div>
    </Header>
  );
};

const LeftMenu = ({ mode }) => {
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    null
  );
  useEffect(() => {
    // Set the selectedMenuItem based on location.pathname
    const pathSegments = location.pathname.split("/");
    let key;

    if (pathSegments[1] === "customer") {
      setSelectedMenuItem("1");
    }
    if (pathSegments[1] === "driver") {
      setSelectedMenuItem("2");
    }
    if (pathSegments[1] === "reward") {
      setSelectedMenuItem("3");
    }
    if (pathSegments[1] === "about-us") {
      setSelectedMenuItem("4");
    }
    if (pathSegments[1] === "contact-us") {
      setSelectedMenuItem("4");
    }
    if (pathSegments[1] === "legal") {
      setSelectedMenuItem("5");
    }
  }, [location.pathname]);

  const handleClick = (e) => {
    // setCurrent(e.key);
    setSelectedMenuItem(e.key);
  };
  return (
    <Menu
      mode={mode}
      className="xl:w-full xl:flex xl:items-center xl:justify-around h-4/5"
      selectedKeys={[selectedMenuItem]}
      onClick={handleClick}
    >
      {/* <SubMenu
        className="pe-0"
        title={
          <span className="font-normal text-base ">
            Solutions{" "}
            {mode === "horizontal" && (
              <i className="fa-solid fa-angle-down ps-1"></i>
            )}
          </span>
        }
        key={"sample"}
      >
        <MenuItemGroup>
          <Menu.Item key="1" className="font-normal text-base">
            Option 1
          </Menu.Item>
          <Menu.Item key="2" className="font-normal text-base">
            Option 2
          </Menu.Item>
        </MenuItemGroup>
      </SubMenu> */}
      <Menu.Item key="1" className="pe-0">
        <Link to="/customer" className="font-normal text-base ">
          CUSTOMER
        </Link>
      </Menu.Item>
      <Menu.Item key="2" className="pe-0">
        <Link to="/driver" className="font-normal text-base ">
          DRIVER
        </Link>
      </Menu.Item>
      <Menu.Item key="3" className="pe-0">
        <Link to="/reward" className="font-normal text-base ">
          REWARD
        </Link>
      </Menu.Item>
      {/* <SubMenu
        className="pe-0"
        title={
          <span className="font-normal text-base ">
            About Us{" "}
            {mode === "horizontal" && (
              <i className="fa-solid fa-angle-down ps-1"></i>
            )}
          </span>
        }
        key="sample2"
      >
        <MenuItemGroup>
          <Menu.Item key="11" className="font-normal text-base">
            Option 1
          </Menu.Item>
          <Menu.Item key="22" className="font-normal text-base">
            Option 2
          </Menu.Item>
        </MenuItemGroup>
      </SubMenu> */}
      <Menu.Item key="4" className="pe-0">
        <Link to="/about-us" className="font-normal text-base ">
          ABOUT US
        </Link>
      </Menu.Item>
      <Menu.Item key="5" className="pe-0">
        <ScrollLink
          to="contact-us-section" // Specify the id or name of the target section
          spy={true}
          smooth={true}
          offset={-70} // Adjust the offset as needed
          duration={500}
          className="font-normal text-base"
        >
          CONTACT US
        </ScrollLink>
      </Menu.Item>
      <Menu.Item key="6" className="pe-0">
        <Link to="/legal" className="font-normal text-base ">
          Legal
        </Link>
      </Menu.Item>
    </Menu>
  );
};

const RightMenu = ({ mode }) => {
  const navigate = useNavigate();
  return (
    <Menu mode={mode} className="xl:flex justify-around">
      <Menu.Item key="55">
        <Button
          className="text-base font-extrabold bg-[#FA0001] ant-custom-btn text-white xl:w-[200px] h-[40px]"
          block={mode == "vertical" ? true : false}
        >
          Track your Package
        </Button>
      </Menu.Item>
      <Menu.Item key="77">
        <Button
          className="text-base font-extrabold bg-[#FA0001] ant-custom-btn text-white xl:w-[171px] h-[40px]"
          block={mode == "vertical" ? true : false}
          onClick={() => navigate("/admin/")}
        >
          Sign Up Now
        </Button>
      </Menu.Item>
    </Menu>
  );
};
