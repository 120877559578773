import React from "react";
import { Button, Col, Modal, Row } from "antd";
type Props = {};

const WhyWe = (props: Props) => {
  return (
    <section className="container mx-auto mt-3 lg:pb-20 pb-10 border-b-2 border-[#C6C6C6]">
      <p className="text-6xl text-black mb-5 font-black text-center font-Roboto">Why We?</p>
      <Row gutter={[40, 48]}>
        <Col xl={6} md={12} sm={24} xs={24}>
          <div className="shadow h-[222px] flex flex-col justify-center py-5 px-4  rounded-xl">
            <p className="text-FA0001 font-black text-xl text-center font-Roboto">
              Safe & Fast same day delivery
            </p>
            <p className="text-black font-normal text-sm text-center font-Roboto">
              Our Drivers will deliver your Parcels with care & safe
            </p>
          </div>
        </Col>
        <Col xl={6} md={12} sm={24} xs={24}>
          <div className="shadow  flex flex-col justify-center h-[222px] py-5 px-4  rounded-xl">
            <p className="text-FA0001 font-black text-xl text-center font-Roboto">
              Express Direct Delivery
            </p>
            <p className="text-black font-normal text-sm text-center font-Roboto">
              Fastest Delivery Service; Driver only allowed to accept Single
              Order
            </p>
          </div>
        </Col>
        <Col xl={6} md={12} sm={24} xs={24}>
          <div className="shadow  flex flex-col justify-center h-[222px] py-5 px-4  rounded-xl">
            <p className="text-FA0001 font-black text-xl text-center font-Roboto">
              Budget Saver Delivery
            </p>
            <p className="text-black font-normal text-sm text-center font-Roboto">
              Delayed Same Day Delivery within the scheduled Time
            </p>
          </div>
        </Col>
        <Col xl={6} md={12} sm={24} xs={24}>
          <div className="shadow  flex flex-col justify-center h-[222px] py-5 px-4  rounded-xl ">
            <p className="text-FA0001 font-black text-xl text-center font-Roboto">
              Multi Stop Delivery
            </p>
            <p className="text-black font-normal text-sm text-center font-Roboto">
              Customers can choose multi stops in a route deliver more parcels
              in single order
            </p>
          </div>
        </Col>
      </Row>
      <Row justify="center" gutter={[40, 48]} className="mt-5">
        <Col xl={6} md={12} sm={24} xs={24}>
          <div className="shadow flex flex-col justify-center h-[222px] py-5 px-4  rounded-xl">
            <p className="text-FA0001 font-black text-xl text-center font-Roboto">
              Premium Delivery
            </p>
            <p className="text-black font-normal text-sm text-center font-Roboto">
              Door to Door Delivery; Deliver at your doorstep E.g Condominim &
              Apartments etc
            </p>
          </div>
        </Col>
        <Col xl={6} md={12} sm={24} xs={24}>
          <div className="shadow  flex flex-col justify-center py-5 px-4 h-[222px]  rounded-xl">
            <p className="text-FA0001 font-black text-xl text-center font-Roboto">
              Return Trip
            </p>
            <p className="text-black font-normal text-sm text-center font-Roboto">
              Driver will deliver the parcel and wait within minimum waiting
              time and collect back parcel and return to the sender
            </p>
          </div>
        </Col>
        <Col xl={6} md={12} sm={24} xs={24}>
          <div className="shadow  flex flex-col justify-center h-[222px] py-5 px-4  rounded-xl">
            <p className="text-FA0001 font-black text-xl text-center font-Roboto">
              Realtime Tracking
            </p>
            <p className="text-black font-normal text-sm text-center font-Roboto">
              Tracking your delivery progress in Real-time
            </p>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default WhyWe;
