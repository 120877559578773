import { signInWithEmailAndPassword } from "firebase/auth";
import {
    CLEAR_LOGIN,
    CLEAR_LOGIN_ERROR,
    LOGIN_EMAIL,
    LOGIN_PASSWORD,
    LOGIN_REDIRECT,
    LOGIN_ERROR,
    PASSWORD_ERROR,
    SERVER_ERROR,
    SHOW_PASSWORD,
  } from "../constants/redux";
import { auth, db } from "../config/firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { loginAPI } from "../api/loginAPI";

export const clearLogin = () => ({
    type : CLEAR_LOGIN
})

export const onChangeShowPassword = () => async (dispatch, getState) => {
    const {login} = getState();
    const {showPassword} = login
    dispatch({
        type : SHOW_PASSWORD,
        showPassword : !showPassword
    })
}

export const onChangeEmail = (value) => async (dispatch,getState) => {
    dispatch({
        type :LOGIN_EMAIL,
        email : value
    })
    dispatch({
        type:PASSWORD_ERROR,
        passwordError : ''
    })
    dispatch({
        type: SERVER_ERROR,
        serverError : ''
    })
}

export const onChangePassword = (value) => async (dispatch,getState) => {
    dispatch({
        type :LOGIN_PASSWORD,
        password : value
    })
    dispatch({
        type:PASSWORD_ERROR,
        passwordError : ''
    })
    dispatch({
        type: SERVER_ERROR,
        serverError : ''
    })
}

export const loginSubmit = () => async(dispatch,getState) => {
    try{
        const {login} = getState()
        const {email,password} = login

    //     const loginResponse = await signInWithEmailAndPassword(auth,email,password)
    //     const q = query(collection(db, "users"), where("uid", "==", loginResponse?.user?.uid));
    //     const doc = await getDocs(q);
    //     const data = doc.docs[0].data();
    //    const new_data  = {...loginResponse.user, ...data}
        // 

    // custom login
        const loginResult = await loginAPI(email,password);
        if(loginResult){
            localStorage.setItem('user', JSON.stringify(loginResult || {}))
            dispatch({
                type : LOGIN_REDIRECT,
                redirect: true,
                payload : loginResult
            })
            dispatch({
                type:PASSWORD_ERROR,
                passwordError : ''
            })
            dispatch({
                type: SERVER_ERROR,
                serverError : ''
            })
        }else{
            dispatch({
                type: SERVER_ERROR,
                serverError : 'Account not found.'
            })
        }
    }catch(error){
        console.log(error.message)
        if(error.message === 'User inactive!!!'){
            dispatch({
                type: SERVER_ERROR,
                serverError : 'Account not found.'
            })
        }else if(error.message === 'Invalid user name or password!!!'){
            dispatch({
                type:PASSWORD_ERROR,
                passwordError : 'Wrong password'
            })
        }else if (error.code === 'auth/too-many-requests'){
            dispatch({
                type: SERVER_ERROR,
                serverError : 'Account locked out. You can try again after 10 minutes'
            })
        }
    }

}