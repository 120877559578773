import {
  LOADING,
  PRICE_MODAL,
  SELECTED_VEHICLE_TYPE,
  SERVICE_SELECTION,
  VECHILE_LIST_SUCCESS,
  VECHILE_MAPPING_DETAILS_SUCCESS,
  VECHILE_MAPPING_ID,
  VECHILE_MAPPING_LIST,
} from "../constants/redux";
import { VEchileMappingList, VechileList } from "../interface/interface";

// Define action types
type VechileListSuccessAction = {
  type: typeof VECHILE_LIST_SUCCESS;
  vechileList?: VechileList[] | any;
};

type VechileMappingDetailsSuccessAction = {
  type: typeof VECHILE_MAPPING_DETAILS_SUCCESS;
  vechilePrizing?: VEchileMappingList[] | any;
};

type VehicleMappingListAction = {
  type: typeof VECHILE_MAPPING_LIST;
  data?: { vehicleId: number; vehicleName: string }[] | any;
};

type SelectedVehicleList = {
  type: typeof SELECTED_VEHICLE_TYPE;
  data?: { vehicleId: number; vehicleName: string }[] | any;
};
type Modal = {
  type: typeof PRICE_MODAL;
  modal: boolean;
};
type VehicleID = {
  type: typeof VECHILE_MAPPING_ID;
  id: number;
};

type Loading = {
  type: typeof LOADING;
  loading: boolean;
};
type service = {
  type: typeof SERVICE_SELECTION;
  service: any;
};
// Define the union type for all possible actions
type VechileAction =
  | VechileListSuccessAction
  | VechileMappingDetailsSuccessAction
  | VehicleMappingListAction
  | SelectedVehicleList
  | Modal
  | Loading
  | VehicleID | service;

// Define the state type
type VechileState = {
  vechileList: VechileList[];
  vechile_pricing: VEchileMappingList[];
  vehicleMappingList: { id: number; vehicleName: string }[];
  selectedVehicleList: { id: number; vehicleName: string } | any;
  modal: boolean;
  service : any;
  loading: boolean;
  id: number;
};

// Define the initial state
const initialState: VechileState = {
  vechileList: [],
  vechile_pricing: [],
  vehicleMappingList: [],
  selectedVehicleList: {},
  modal: false,
  loading: true,
  id: 0,
  service:{},
};

// Define the reducer
export const vehicleReducer = (
  state: VechileState = initialState,
  action: VechileAction
): VechileState => {
  switch (action.type) {
    case VECHILE_LIST_SUCCESS:
      return {
        ...state,
        vechileList: action.vechileList,
      };

    case VECHILE_MAPPING_DETAILS_SUCCESS:
      return {
        ...state,
        vechile_pricing: action.vechilePrizing,
      };
    case VECHILE_MAPPING_LIST:
      return {
        ...state,
        vehicleMappingList: action.data,
      };
    case SELECTED_VEHICLE_TYPE:
      return {
        ...state,
        selectedVehicleList: action.data,
      };
    case PRICE_MODAL:
      return {
        ...state,
        modal: !state.modal,
      };
    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case VECHILE_MAPPING_ID:
      return { ...state, id: action.id };
    case SERVICE_SELECTION:
      return{...state, service:action.service}
    default:
      return state;
  }
};
