// Login
export const CLEAR_LOGIN = 'CLEAR_LOGIN'
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR'
export const LOGIN_EMAIL = 'LOGIN_EMAIL'
export const LOGIN_PASSWORD = 'LOGIN_PASSWORD'
export const SHOW_PASSWORD = 'SHOW_PASSWORD'
export const LOGIN_REDIRECT = 'LOGIN_REDIRECT'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const PASSWORD_ERROR = 'PASSWORD_ERROR'
export const SERVER_ERROR = 'SERVER_ERROR'

// Registeration
export const USER_FULL_NAME = 'USER_FULL_NAME'
export const USER_EMAIL_ID = 'USER_EMAIL_ID'
export const USER_MOBILE_NUMBER = 'USER_MOBILE_NUMBER'
export const USER_PASSWORD = 'USER_PASSWORD'
export const USER_CONFIRM_PASSWORD = 'USER_CONFIRM_PASSWORD'
export const USER_EMAIL_ERROR = 'USER_EMAIL_ERROR'
export const USER_PASSWORD_ERROR = 'USER_PASSWORD_ERROR'
export const USER_CONFIREM_PASSWORD_ERROR = 'USER_CONFIREM_PASSWORD_ERROR'
export const USER_EMAIL_SERVER_ERROR = 'USER_EMAIL_SERVER_ERROR'
export const USER_PASSWORD_SERVER_ERROR = 'USER_PASSWORD_ERROR'
export const USER_MOBILE_NUMBER_ERROR = 'USER_MOBILE_NUMBER_ERROR'
export const USER_SERVER_ERROR = 'USER_SERVER_ERROR'
export const SHOW_CONFIRM_PASSWORD = 'SHOW_CONFIRM_PASSWORD'
export const USER_NAME_ERROR = 'USER_NAME_ERROR'

// Role

export const ROLE_PERMISSION_NAME = 'ROLE_PERMISSION_NAME'
export const ROLE_PERNISSION_DESCRIPTION = 'ROLE_PERNISSION_DESCRIPTION'
export const ROLE_PERMISSION_STATUS = 'ROLE_PERMISSION_STATUS'
export const ROLE_PERMISSION_LOADING = 'ROLE_PERMISSION_LOADING'
export const ROLE_PERMISSION_SUCCESS = 'ROLE_PERMISSION_SUCCESS'
export const ROLE_PERMISSION_FAILURE = 'ROLE_PERMISSION_FAILURE'
// actions/types.js
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';

//User
export const USER_LIST_USERID ='USER_LIST_USERID'
export const USER_LIST_PASSWORD ="USER_LIST_PASSWORD"
export const USER_LIST_FIRSTNAME ='USER_LIST_FIRSTNAME'
export const USER_LIST_LASTNAME ='USER_LIST_LASTNAME'
export const USER_LIST_EMAILID ='USER_LIST_EMAIL_ID'
export const USER_LIST_IsACTIVE = 'USER_LIST_IsACTIVE'
export const USER_LIST_ROLEID = 'USER_LIST_ROLEID'
export const USER_LIST_LOADING = 'USER_LIST_LOADING'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE'
// actions/types.js
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const VECHILE_LIST_SUCCESS = 'VECHILE_LIST_SUCCESS';
export const VECHILE_MAPPING_DETAILS_SUCCESS = 'VECHILE_MAPPING_DETAILS_SUCCESS'
export const VECHILE_MAPPING_LIST = 'VECHILE_MAPPING_LIST'
export const SERVICE_SELECTION ="SERVICE_SELECTION";
export const SELECTED_VEHICLE_TYPE = 'SELECTED_VEHICLE_TYPE'
export const PRICE_MODAL = 'PRICE_MODAL'
export const VECHILE_MAPPING_ID = 'VECHILE_MAPPING_ID'
export const LOADING = 'LOADING'

export const DRIVER_TAB= 'DRIVER_TAB'
export const DRIVER_FIRST_NAME = 'DRIVER_FIRST_NAME'
export const DRIVER_LAST_NAME ='DRIVER_LAST_NAME'
export const DRIVER_EMAIL ='DRIVER_EMAIL'
export const DRIVER_PHONE = 'DRIVER_PHONE'
export const DRIVER_DOB='DRIVER_DOB'
export const DRIVER_ADDRESS = 'DRIVER_ADDRESS'
export const DRIVER_VECHILE_TYPE = 'DRIVER_VECHILE_TYPE'
export const DRIVER_VEHICLE_NUMBER = 'DRIVER_VEHICLE_NUMBER'
export const DRIVER_LICENSE_NUMBER='DRIVER_LICENSE_NUMBER'
export const DRIVER_VEHICLE_PERMIT = 'DRIVER_VEHICLE_PERMIT'
export const DRIVER_PROFILE_PHOTO = 'DRIVER_PROFILE_PHOTO'
export const DRIVER_VEHICLE_PIC = 'DRIVER_VEHICLE_PIC'
export const DRIVER_GOVT_ID = 'DRIVER_GOVT_ID'
export const DRIVER_LICENSE_PIC = 'DRIVER_LICENSE_PIC'
export const DRIVER_PAN_CARD = 'DRIVER_PAN_CARD'
export const DRIVER_RC_PIC = 'DRIVER_RC_PIC'
export const DRIVER_VEHICLE_PERMIT_PIC = 'DRIVER_VEHICLE_PERMIT_PIC'
export const DRIVER_VEHICLE_INSURANCE_PIC = 'DRIVER_VEHICLE_INSURANCE_PIC'
export const DRIVER_VEHICLE_MODEL = 'DRIVER_VEHICLE_MODEL'
export const DRIVER_FORM_SUCCESS = 'DRIVER_FORM_SUCCESS'
