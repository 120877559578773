export interface ServiceInterceptorResponse<T = any> {
  data?: T;
  status: number;
  statusText: string;
  headers: Headers;
}

export interface Headers {
  [key: string]: string;
}
export const headers: Headers = {
  "Content-Type": "application/json",
};

export const handleResponse = async (response: Response) => {
  if (!response.ok) {
    const errorBody = await response.json();
    console.error("Bad Request Error:", response.status, errorBody);
    throw new Error(errorBody.message || "Request failed");
  }
  return response.json();
};

export const authToken = () =>{
  const userString: string | null = localStorage.getItem('user');
    let token: any = null;

    if (userString && userString.trim() !== '') {
      try {
        token = JSON.parse(userString);
        return token
      } catch (error) {
        console.error("Error parsing user token:", error);
        // Handle the parsing error if needed
      }
    }
}

export const serviceInterceptor = async (
  url: string,
  method: string,
  body?: Record<string, any>
) => {
  try {
    
    const token = authToken()
    const updatedHeaders: Headers|any = {
      ...headers,
      "Authorization": token ? `Bearer ${token.token}` : null,
    };

    const response = await fetch(url, {
      method,
      headers: updatedHeaders,
      body: body ? JSON.stringify(body) : undefined,
      credentials:'include'
    });

    return handleResponse(response);
  } catch (error) {
    console.error(`Error in ${method} request to ${url}:`, error);
    throw error;
  }
};