import React, { useEffect } from "react";
import Logo from "../../../images/LOGO/LOGO.png";
import Banner from "../../../images/Driver/delivery.png";
import Tabs from "./components/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
type Props = {};

const DriverForm = (props: Props) => {
  const { driver }: any = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (driver?.redirect) {
      // console.log('first')
      message.open({
        type: "success",
        content: "Your form submitted successfully!",
        duration: 10,
      });
      // navigate(-1)
      dispatch({
        type: "RESET",
      });
    }
  }, [driver.redirect, navigate]);
  return (
    <section className="container mx-auto rounded-lg shadow-lg driverForm py-3 my-5">
      {/* <div className="flex flex-row justify-between">
        <div className="logo mb-4">
          <img className="" alt="" src={Logo} />
        </div>
      </div> */}
      <div className="driverForm-content">
        <div className="text-center">
          {/* <img src={Banner} alt="" className="w-full" /> */}
          {/* <p className="text-red font-bold text-3xl md:text-5xl ms-3 font-Poppins">
            Attach your Vehicle
          </p> */}
        </div>
        <Tabs />
      </div>
    </section>
  );
};

export default DriverForm;
