import { Button, Checkbox, Form, Input, Upload, message } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  driverSubmit,
  onChangeGovtID,
  onChangeInsurance,
  onChangeLicense,
  onChangePanCard,
  onChangePermit,
  onChangeProfilePic,
  onChangeRC,
  onChangeVehiclePic,
  tabs,
} from "../../../../../actions/driverForm";
import playstore from "../../../../../images/publicWebsites/playStore.png";
import appstore from "../../../../../images/publicWebsites/AppStore.png";
import { useNavigate } from "react-router-dom";
const UploadDocuments: React.FC<any> = () => {
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();
  const { driver }: any = useSelector((state) => state);
  const { uploadDocuments } = driver;
  const {
    profilePic,
    vehiclePic,
    govtID,
    driverLicense,
    panCard,
    rc,
    vehiclePermit,
    vehicleInsurance,
  } = uploadDocuments;
  const [agreedToTerms, setAgreedToTerms] = React.useState(false);
  const navigate = useNavigate();
  const removeKey = (data: any) => {
    const removedObj = data.file;
    delete removedObj["uid"];
    return removedObj;
  };

  const handleProfilePhoto = (file: any) => {
    if (file) {
      // setProfilePhoto(file.fileList.slice(-1));
      dispatch(onChangeProfilePic(removeKey(file)));
    }
  };
  const handleVehiclePic = (file: any) => {
    if (file) {
      // setVechilePic(file.fileList.slice(-1));
      dispatch(onChangeVehiclePic(removeKey(file)));
    }
  };
  const handlePanCard = (file: any) => {
    if (file) {
      dispatch(onChangePanCard(removeKey(file)));
    }
  };
  const handleRC = (file: any) => {
    if (file) {
      dispatch(onChangeRC(removeKey(file)));
    }
  };
  const handlegovtID = (file: any) => {
    if (file) {
      dispatch(onChangeGovtID(removeKey(file)));
    }
  };
  const handleDrivingLicence = (file: any) => {
    if (file) {
      dispatch(onChangeLicense(removeKey(file)));
    }
  };
  const handleVehiclePermit = (file: any) => {
    if (file) {
      dispatch(onChangePermit(removeKey(file)));
    }
  };
  const handleVehicleInsurance = (file: any) => {
    if (file) {
      dispatch(onChangeInsurance(removeKey(file)));
    }
  };
  const [loading,setLoading] = React.useState<boolean>(false)
  React.useEffect(() =>{
    if(driver?.redirect){
      setLoading(false)
    }
  },[driver.redirect])
  return (
    <div className="">
      <Form
        size="large"
        name="driverVehicleInfoForm"
        form={form}
        colon={true}
        // requiredMark="optional"
        layout="vertical"
        className="lg:grid lg:grid-cols-2 gap-x-12 gap-y-3"
        // labelAlign="left"
        labelCol={{
          span: 12,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={() => {
          if (agreedToTerms) {
            setLoading(true)
            dispatch(driverSubmit());
          } else {
            message.warning(
              "Please agree to the Terms & Conditions and Privacy Policy."
            );
          }
        }}
      >
        <Form.Item
          name="profilePhoto"
          className="relative"
          rules={[
            {
              required: false,
              message: "Profile Photo is required.",
            },
          ]}
        >
          <Upload
            className={"driver-file-upload"}
            onChange={handleProfilePhoto}
            showUploadList={false}
            beforeUpload={() => false}
          >
            <Button
              className={`!h-24 border-[#D3D2D2] bg-[#F4F4F4] border !text-xl w-full font-Roboto font-normal p-6`}
            >
              <span className="truncate w-full">
                {profilePic?.name || "Profile Photo"}
              </span>
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="vehiclePic"
          className="relative"
          rules={[
            {
              required: true,
              message: "Vehicle Picture is required.",
            },
          ]}
        >
          <Upload
            className={"driver-file-upload"}
            onChange={handleVehiclePic}
            showUploadList={false}
            beforeUpload={() => false}
          >
            <Button
              className={`!h-24 border-[#D3D2D2] bg-[#F4F4F4] border !text-xl w-full font-Roboto font-normal p-6`}
            >
              <span className="truncate w-full">
                {vehiclePic?.name || "Vehicle Picture"}
              </span>
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="govtID"
          className="relative"
          rules={[
            {
              required: true,
              message: "Government ID is required.",
            },
          ]}
        >
          <Upload
            className={"driver-file-upload"}
            onChange={handlegovtID}
            showUploadList={false}
            beforeUpload={() => false}
          >
            <Button
              className={`!h-24 border-[#D3D2D2] bg-[#F4F4F4] border !text-xl w-full font-Roboto font-normal p-6`}
            >
              <span className="truncate w-full">
                {govtID?.name || "Government ID"}
              </span>
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="drivingLicense"
          className="relative"
          rules={[
            {
              required: true,
              message: "Driving License is required.",
            },
          ]}
        >
          <Upload
            className={"driver-file-upload"}
            onChange={handleDrivingLicence}
            showUploadList={false}
            beforeUpload={() => false}
          >
            <Button
              className={`!h-24 border-[#D3D2D2] bg-[#F4F4F4] border !text-xl w-full font-Roboto font-normal p-6`}
            >
              <span className="truncate w-full">
                {driverLicense?.name || "Driving License"}
              </span>
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="panCard"
          className="relative"
          rules={[
            {
              required: false,
              message: "Pan Card is required.",
            },
          ]}
        >
          <Upload
            className={"driver-file-upload"}
            onChange={handlePanCard}
            showUploadList={false}
            beforeUpload={() => false}
          >
            <Button
              className={`!h-24 border-[#D3D2D2] bg-[#F4F4F4] border !text-xl w-full font-Roboto font-normal p-6`}
            >
              <span className="truncate w-full">
                {panCard?.name || "Pan Card"}
              </span>
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="rc"
          className="relative"
          rules={[
            {
              required: true,
              message: "Registration Certificate (RC) is required.",
            },
          ]}
        >
          <Upload
            className={"driver-file-upload"}
            onChange={handleRC}
            showUploadList={false}
            beforeUpload={() => false}
          >
            <Button
              className={`!h-24 border-[#D3D2D2] bg-[#F4F4F4] border !text-xl w-full font-Roboto font-normal p-6`}
            >
              <span className="truncate w-full">
                {rc?.name || "Registration Certificate (RC)"}
              </span>
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="vehiclePermit"
          className="relative"
          rules={
            [
              // {
              //   required: true,
              //   message: "Vehicle Permit is required.",
              // },
            ]
          }
        >
          <Upload
            className={"driver-file-upload"}
            onChange={handleVehiclePermit}
            showUploadList={false}
            beforeUpload={() => false}
          >
            <Button
              className={`!h-24 border-[#D3D2D2] bg-[#F4F4F4] border !text-xl w-full font-Roboto font-normal p-6`}
            >
              <span className="truncate w-full">
                {vehiclePermit?.name || "Vehicle Permit"}
              </span>
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="vehicleInsurance"
          className="relative"
          rules={[
            {
              required: true,
              message: "Vehicle Insurance is required.",
            },
          ]}
        >
          <Upload
            className={"driver-file-upload"}
            onChange={handleVehicleInsurance}
            showUploadList={false}
            beforeUpload={() => false}
          >
            <Button
              className={`!h-24 border-[#D3D2D2] bg-[#F4F4F4] border !text-xl w-full font-Roboto font-normal p-6`}
            >
              <span className="truncate w-full">
                {vehicleInsurance?.name || "Vehicle Insurance"}
              </span>
            </Button>
          </Upload>
        </Form.Item>
      </Form>
      <p className="text-xl font-medium !text-center mt-4">
        We’ll text you to confirm your number
      </p>
      <div className="text-center">
        <Checkbox
          className="text-center text-xl font-medium"
          onChange={(e) => setAgreedToTerms(e.target.checked)}
        >
          I agree to TransitNow’s{" "}
          <strong className="text-red">Terms & Conditions</strong> and{" "}
          <strong className="text-red">Privacy Policy</strong>
        </Checkbox>
      </div>
      <div className="w-full flex justify-center">
        <Button
          htmlType="submit"
          loading={loading}
          onClick={() => form.submit()}
          className="bg-red ant-custom-btn uppercase font-bold !text-2xl text-white !mt-10 !h-16 w-[332px] !rounded-xl hover:border-red"
        >
          SUBMIT NOW
        </Button>
      </div>
      <div className="w-full flex justify-center mt-5">
        <div className="lg:flex">
          <img
            className="mb-3 w-60 h-16 me-3 cursor-pointer"
            src={playstore}
            alt=""
          />
          <img className="w-60 h-16 cursor-pointer" src={appstore} alt="" />
        </div>
      </div>
    </div>
  );
};

export default UploadDocuments;
