import {
  CLEAR_LOGIN,
  CLEAR_LOGIN_ERROR,
  LOGIN_EMAIL,
  LOGIN_PASSWORD,
  LOGIN_REDIRECT,
  SERVER_ERROR,
  PASSWORD_ERROR,
  SHOW_PASSWORD,
} from "../constants/redux";
type ActionType = {
  type: string;
  payload?: any;
  email: string;
  password: string;
  passwordError:string;
  serverError: string;
  showPassword: boolean;
  redirect: boolean;
};

type State = {
  email: string;
  password: string;
  serverError: string;
  passwordError:string;
  showPassword: boolean;
  redirect: boolean;
};

const initialState: State | any = {
  email: "",
  password: "",
  serverError: '',
  passwordError:'',
  showPassword: false,
  redirect: false,
};

export const LoginReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case LOGIN_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case LOGIN_PASSWORD:
      return {
        ...state,
        password: action.password,
      };
    case CLEAR_LOGIN:
      return {
        ...state,
        ...initialState,
      };
    case SHOW_PASSWORD:
      return {
        ...state,
        showPassword: action.showPassword,
      };
    case LOGIN_REDIRECT:
      return {
        ...state,
        redirect: action.redirect,
        loggedInUser: action.payload,
      };
    case SERVER_ERROR:
      return {
        ...state,
        serverError: action.serverError
      }
    case PASSWORD_ERROR:
      return {
        ...state, 
        passwordError: action.passwordError
      }
    case 'LOGIN_RESET':
      return initialState
    default:
      return state;
  }
};
