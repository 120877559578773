import { deleteUser, getAllUsers } from "../api/usersAPI";
import {
    DELETE_USER_SUCCESS,
    USER_LIST_LOADING,
  USER_LIST_SUCCESS,
} from "../constants/redux";

// export const listUsers = () => async (dispatch) => {
//   try {
//     dispatch({type : USER_LIST_LOADING})
//     const data = await getAllUsers();
//     console.log(data.length)
//     if (data) {
//         dispatch({
//           type: USER_LIST_SUCCESS,
//           users_List: data,
//           length : data.length
//       });
//     }
//   } catch (error) {}
// };

export const listUsers = () => async (dispatch) => {
  try {
    dispatch({type : USER_LIST_LOADING})
    const data = await getAllUsers();
    console.log(data.length)
    if (data) {
        dispatch({
        type: USER_LIST_SUCCESS,
        users_List: data,
        length : data.length
      });
    }
  } catch (error) {}
};

export const deleteUserSuccess = (userId) => async(dispatch) =>{
    const data = await deleteUser(userId)
    console.log(data)
    dispatch({
      type : DELETE_USER_SUCCESS,
      userID : userId
  })
}

